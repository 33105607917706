import { Theme } from "./theme";

export const getLineHeight = (fontSize, multiplier = 150) => {
  const factor = multiplier / 100;

  return parseInt(fontSize * factor, 10);
};

export const FONT_FAMILYS = {
  TITLE_REGULAR: "Tiempos Fine Medium",
  BODY_LIGHT: "Brown Light",
  BODY_REGULAR: "Brown Regular",
  BODY_BOLD: "Brown Bold",
  ICON: "Brown Bold",
};

export const base = {
  fontFamily: FONT_FAMILYS.BODY_REGULAR,
  fontSize: 16,
  lineHeight: getLineHeight(16, 130),
  color: Theme.textColor,
};

export const body = {
  ...base,
};

export const bodyLight = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_LIGHT,
};

export const bodyBold = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
};

export const subTitle = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
};

export const ingress = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
  fontSize: 20,
  lineHeight: getLineHeight(20, 135),
};

export const h1 = {
  ...base,
  fontFamily: FONT_FAMILYS.TITLE_REGULAR,
  fontSize: 48,
  lineHeight: getLineHeight(48, 115),
};

export const h2 = {
  ...base,
  fontFamily: FONT_FAMILYS.TITLE_REGULAR,
  fontSize: 32,
  lineHeight: getLineHeight(32, 130),
};

export const h3 = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
  fontSize: 20,
  lineHeight: getLineHeight(20, 135),
};

export const h4 = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_REGULAR,
  fontSize: 15,
  lineHeight: getLineHeight(15, 100),
  textTransform: "uppercase",
};

export const keyword = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_REGULAR,
  letterSpacing: 2,
  textTransform: "uppercase",
};

// Text for UI elements
export const buttonLabel = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
};

export const inputLabel = {
  ...base,
  fontSize: 12,
  lineHeight: getLineHeight(12, 100),
};

export const rangeLabel = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_LIGHT,
  fontSize: 20,
  lineHeight: 20,
  color: Theme.textColorFaded,
};

export const inputText = {
  ...base,
  lineHeight: getLineHeight(16, 125),
};

export const closeLink = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
};

export const playAgain = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
  fontSize: 12,
  lineHeight: getLineHeight(12, 100),
  letterSpacing: 1.5,
  textTransform: "uppercase",
};

export const accordationTitle = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_REGULAR,
  fontSize: 12,
  lineHeight: getLineHeight(12, 100),
  letterSpacing: 1.5,
  textTransform: "uppercase",
};

export const chapterHeading = {
  ...base,
  marginBottom: 2,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
};

export const subChapterHeading = {
  ...base,
  lineHeight: getLineHeight(base.fontSize, 131.25),
  fontFamily: FONT_FAMILYS.BODY_REGULAR,
};

export const resultBoxHeading = {
  ...base,
  fontFamily: FONT_FAMILYS.TITLE_REGULAR,
  fontSize: 24,
  lineHeight: getLineHeight(24, 115),
};

export const resultBoxSubHeading = {
  ...base,
  fontFamily: FONT_FAMILYS.BODY_BOLD,
  lineHeight: 20,
};

export const resultBoxText = {
  ...base,
  fontSize: 18,
  lineHeight: getLineHeight(18, 135),
};

export const iconFontSmall = {
  ...base,
  fontFamily: FONT_FAMILYS.ICON,
  fontWeight: "900",
  fontSize: 16,
  lineHeight: getLineHeight(16, 100),
};

export const iconFontMedium = {
  ...iconFontSmall,
  fontSize: 24,
  lineHeight: getLineHeight(24, 100),
};

export const iconFontLarge = {
  ...iconFontSmall,
  fontSize: 55,
  lineHeight: getLineHeight(55, 100),
};

// Survey styles
export const surveyHeader = {
  ...h1,
  fontSize: 36,
  lineHeight: getLineHeight(36, 100),
};

export const surveyIntro = {
  ...base,
};

export const surveyText = {
  ...h3,
};

export const surveyQuestionOption = {
  ...base,
  fontWeight: "600",
};

export const surveyQuestionOptionLabel = {
  ...base,
};
