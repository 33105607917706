import React from "react";
import { Image, Text, View } from "react-native";
import { Texts } from "../../styles";
import { Colors } from "../../styles/colors";
import { Spacer } from "../Spacer";
import { dir, eu, ltu, nbtn, vbtn } from "./logos_ig/index";

const images = [
  { source: ltu, desc: "Luleå tekniska universitet" },
  { source: eu, desc: "EU" },
  { source: dir, desc: "Datacenter Innovation Region" },
  { source: nbtn, desc: "Region Norrbotten" },
  { source: vbtn, desc: "Region Västerbotten" },
];

const FooterLogos = (props) => (
  <View
    style={{
      ...props.style,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Colors.white,
      textAlign: "center",
      padding: 50,
    }}
  >
    <Text style={{ ...Texts.base, color: Colors.grey }}>
      Övning och utbildning har framtagits med stöd från
    </Text>
    <Spacer size="large" />
    <View
      style={{
        display: "inline",
        textAlign: "center",
        maxWidth: 480,
      }}
    >
      {images.map((image, index) => (
        <Image
          key={index}
          accessibilityLabel={image.desc}
          style={{
            display: "inline-flex",
            resizeMode: "contain",
            width: 120,
            height: 120,
            marginRight: 20,
            marginLeft: 20,
          }}
          source={{
            uri: image.source,
          }}
        />
      ))}
    </View>
  </View>
);

export default FooterLogos;
