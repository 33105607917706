import React from "react";
import { ScrollView, View } from "react-native";
import JustifyContent from "../components/CenteredContent";
import { useResponsive } from "../helpers/functions";
import Renderer, { isComponent } from "../helpers/renderer";
import { Layout, Theme } from "../styles";

const ContentStep = (props) => {
  const { getResponsiveStyle } = props?.getResponsiveStyle
    ? props
    : useResponsive();
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        backgroundColor: Theme.pageBg,
        paddingBottom: props.paddingBottom,
        ...getResponsiveStyle({
          mobile: { paddingTop: props.paddingTop },
          tablet: { paddingTop: 70 },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            padding: 20,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "stretch",
            ...getResponsiveStyle({ mobile: { paddingTop: 20 } }),
          }}
        >
          <JustifyContent
            minWidth={Layout.minContent}
            maxWidth={Layout.maxDesktop}
            position="left"
          >
            {props.substep &&
              props.substep.view_content &&
              props.substep.view_content.map((component, index) => {
                if (isComponent(component, "step_button")) {
                  return Renderer(component, index, undefined, undefined, {
                    gotoNext: props.gotoNext,
                  });
                } else if (isComponent(component, "start_button_set")) {
                  return Renderer(component, index, undefined, undefined, {
                    gotoNext: props.gotoNext,
                    goto: props.goto,
                    getResponsiveStyle: getResponsiveStyle,
                  });
                } else if (isComponent(component, "header")) {
                  // TODO: Handle heading size elsewhere
                  return Renderer(component, index, {
                    color: Theme.textColor,
                    fontSize: 38, //
                    lineHeight: 50,
                  });
                } else {
                  return Renderer(
                    component,
                    index,
                    {
                      color: Theme.textColor,
                    },
                    undefined,
                    { getResponsiveStyle: getResponsiveStyle }
                  );
                }
              })}
          </JustifyContent>
        </ScrollView>
      </View>
    </View>
  );
};

export default ContentStep;
