import * as React from "react";
import Svg, { Circle, Line, Path } from "react-native-svg";
import { Theme } from "../styles";

export const Joy = (props) => {
  let color = Theme.secondary;
  if (props.size) {
    size = props.size;
  }
  if (props.color) {
    color = props.color;
  }

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 38.11 38.11"
    >
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.75)"
      />
      <Circle fill={color} cx="13.07" cy="15.28" r="1.67" />
      <Circle fill={color} cx="25.03" cy="15.28" r="1.67" />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.56,26s1.43,2.63,6.61,2.63,6.37-2.79,6.37-2.79"
      />
    </Svg>
  );
};

export const Lust = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="matrix(0.85, -0.53, 0.53, 0.85, -7.17, 12.88)"
      />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.56,19.42a6.79,6.79,0,0,0,6.61,4.2,6.37,6.37,0,0,0,6.37-4.36"
      />
      <Path
        fill={color}
        d="M15.62,12.47a1.34,1.34,0,0,0-2.27-1,1.36,1.36,0,0,0-.94-.38,1.34,1.34,0,0,0-.93,2.3h0l1.88,1.82,1.74-1.73A1.34,1.34,0,0,0,15.62,12.47Z"
      />
      <Path
        fill={color}
        d="M27.31,12.47A1.34,1.34,0,0,0,26,11.13a1.36,1.36,0,0,0-.94.38,1.34,1.34,0,0,0-2.27,1,1.33,1.33,0,0,0,.41,1h0l1.88,1.82,1.73-1.73A1.32,1.32,0,0,0,27.31,12.47Z"
      />
    </Svg>
  );
};

export const Fear = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.75)"
      />
      <Circle fill={color} cx="17.68" cy="17.87" r="1.67" />
      <Circle fill={color} cx="29.63" cy="17.87" r="1.67" />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="30.17"
        y1="12.57"
        x2="34.87"
        y2="15.46"
      />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="12.08"
        y1="16.2"
        x2="16.55"
        y2="12.98"
      />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.68,24.5s4.69-2,12,0"
      />
    </Svg>
  );
};

export const Anger = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="22.12"
        y1="9.67"
        x2="26.8"
        y2="6.69"
      />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="15.69"
        y1="9.67"
        x2="11.01"
        y2="6.69"
      />
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.75)"
      />
      <Circle fill={color} cx="13.07" cy="13.43" r="1.67" />
      <Circle fill={color} cx="25.03" cy="13.43" r="1.67" />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.12,22.84s6.75-6.79,13.68,0"
      />
    </Svg>
  );
};

export const Shame = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.74)"
      />
      <Circle fill={color} cx="13.07" cy="21.32" r="1.67" />
      <Circle fill={color} cx="25.03" cy="21.32" r="1.67" />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="24.91"
        y1="15.16"
        x2="29.85"
        y2="17.62"
      />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="9.12"
        y1="17.46"
        x2="14.02"
        y2="14.94"
      />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.07,28.76a19.34,19.34,0,0,1,12.64,0"
      />
    </Svg>
  );
};

export const Sadness = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.75)"
      />
      <Circle fill={color} cx="9.79" cy="19.67" r="1.67" />
      <Circle fill={color} cx="21.75" cy="19.67" r="1.67" />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.79,27.12a19.34,19.34,0,0,1,12.64,0"
      />
    </Svg>
  );
};

export const Distaste = (props) => {
  let color = Theme.secondary;
  if (props.color) {
    color = props.color;
  }
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.11">
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="18.97"
        y1="9.67"
        x2="23.65"
        y2="6.69"
      />
      <Line
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="7.02"
        y1="9.71"
        x2="12.56"
        y2="9.69"
      />
      <Circle
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeMiterlimit={10}
        cx="19.05"
        cy="19.05"
        r="18.6"
        transform="translate(-7.17 12.88) rotate(-31.75)"
      />
      <Circle fill={color} cx="9.79" cy="13.43" r="1.67" />
      <Circle fill={color} cx="21.75" cy="13.43" r="1.67" />
      <Path
        fill="none"
        stroke={color}
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.79,22.84s4.52-3.79,12.64-2.38"
      />
    </Svg>
  );
};

export const getColorizedEmotion = (emotion, color) => {
  switch (emotion) {
    case "joy":
      return <Joy color={color} />;
    case "lust":
      return <Lust color={color} />;
    case "fear":
      return <Fear color={color} />;
    case "anger":
      return <Anger color={color} />;
    case "shame":
      return <Shame color={color} />;
    case "sadness":
      return <Sadness color={color} />;
    case "distaste":
      return <Distaste color={color} />;
    default:
      return null;
  }
};

export const EMOTIONMAP = {
  joy: <Joy />,
  lust: <Lust />,
  fear: <Fear />,
  anger: <Anger />,
  shame: <Shame />,
  sadness: <Sadness />,
  distaste: <Distaste />,
};

export const Star = (props) => {
  return (
    <Svg
      viewBox="0 0 110 110"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M52.7254 2.6451C53.9224 1.25315 56.0776 1.25315 57.2746 2.6451L76.1973 24.6498C76.5015 25.0035 76.884 25.2815 77.3145 25.4615L104.09 36.6581C105.783 37.3664 106.449 39.4162 105.495 40.9847L90.4151 65.7811C90.1727 66.1797 90.0266 66.6294 89.9884 67.0944L87.6136 96.019C87.4634 97.8487 85.7198 99.1155 83.9332 98.693L55.6905 92.0133C55.2364 91.9059 54.7636 91.9059 54.3095 92.0133L26.0668 98.693C24.2802 99.1155 22.5366 97.8487 22.3864 96.019L20.0116 67.0944C19.9734 66.6294 19.8273 66.1797 19.5849 65.7811L4.50464 40.9847C3.5507 39.4162 4.21672 37.3664 5.91043 36.6581L32.6855 25.4615C33.116 25.2815 33.4985 25.0035 33.8027 24.6498L52.7254 2.6451Z"
        fill={props.backgroundColor}
      />
      <Path
        d="M70.1113 61.8288C70.1113 61.8288 65.1325 68.3467 55.649 68.3467C46.1655 68.3467 40.9495 61.8288 40.9495 61.8288"
        stroke={props.color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx="44.5942" cy="47.6902" r="2.43015" fill={props.color} />
      <Circle cx="66.4653" cy="47.6902" r="2.43015" fill={props.color} />
    </Svg>
  );
};

export const Back = (props) => (
  <Svg width={8} height={14} viewBox="0 0 8 14" fill="none" {...props}>
    <Path
      d="M7 13L1 7L7 1"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const RightChevron = (props) => (
  <Svg width={69} height={69} viewBox="0 0 69 69" fill="none" {...props}>
    <Path
      d="M34.7031 4L65.0013 34.2982L34.7031 64.5964"
      stroke="black"
      strokeOpacity={0.25}
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const LeftChevron = (props) => (
  <Svg width={69} height={69} viewBox="0 0 69 69" fill="none" {...props}>
    <Path
      d="M34.2969 64.5986L3.9987 34.3005L34.2969 4.00228"
      stroke="black"
      strokeOpacity={0.25}
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
