import authService from "./auth.service";
export default function authHeader() {
  return new Promise((resolve, reject) => {
    authService.getCurrentUser().then(
      (user) => {
        if (user && user.token) {
          resolve({ Authorization: "Bearer " + user.token }); // for Spring Boot back-end
        } else {
          reject({});
        }
      },
      (error) => {
        reject({});
      }
    );
  });
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthService.logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
