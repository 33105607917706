import { Asset } from "expo-asset";
import * as Font from "expo-font";
import React, { useEffect, useState } from "react";
import { Image, View } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { SurveyProvider } from "./components/Survey/surveyContext";
import Root from "./Root";
import Store from "./services/store";
import Navigation from "./Views/Navigation";
import * as SplashScreen from "expo-splash-screen";

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const App = () => {
  const [isReady, setIsReady] = useState(false);

  const cacheImages = (images) => {
    return images.map((image) => {
      if (typeof image === "string") {
        return Image.prefetch(image);
      } else {
        return Asset.fromModule(image).downloadAsync();
      }
    });
  };

  const loadFonts = () => {
    return Font.loadAsync({
      "Tiempos Fine Medium": require("../assets/fonts/TiemposFine/TiemposFine-Medium.otf"),
      "Brown Regular": require("../assets/fonts/Brown/Brown-Regular.otf"),
      "Brown Thin": require("../assets/fonts/Brown/Brown-Thin.otf"),
      "Brown Bold": require("../assets/fonts/Brown/Brown-Bold.otf"),
      "Brown Light": require("../assets/fonts/Brown/Brown-Light.otf"),
    });
  };

  useEffect(() => {
    const _loadAssetsAsync = async () => {
      try {
        const imageAssets = cacheImages([
          require("../assets/images/startimage.png"),
        ]);
        const fontAssets = loadFonts();
        await Promise.all([...imageAssets, fontAssets]);
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setIsReady(true);
        await SplashScreen.hideAsync();
      }
    };
    _loadAssetsAsync();
  }, []);

  if (!isReady) {
    return null;
  } else {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <Store>
          <SurveyProvider>
            <PaperProvider>
              <Root>
                <Navigation />
              </Root>
            </PaperProvider>
          </SurveyProvider>
        </Store>
      </View>
    );
  }
};

export default App;
