import React from "react";
import { TouchableOpacity, Text } from "react-native";
import { Texts, Theme } from "../styles";

export const PrimaryButton = (props) => (
  <TouchableOpacity
    nativeID={props.nativeID}
    onPress={props.onPress}
    style={{
      ...base,
      backgroundColor: props.disabled ? Theme.disabled : Theme.primary,
      ...props.style,
    }}
    disabled={props.disabled}
  >
    <Text
      style={{
        ...Texts.buttonLabel,
        color: Theme.textColorLight,
        paddingTop: 2, // To compensate for font not looking centered
      }}
    >
      {props.text}
    </Text>
  </TouchableOpacity>
);

export const SecondaryButton = (props) => (
  <TouchableOpacity
    onPress={props.onPress}
    style={{
      ...base,
      borderColor: props?.color || Theme.secondary,
      backgroundColor: "transparent",
      ...props.style,
    }}
    disabled={props.disabled}
  >
    <Text
      style={{
        ...Texts.buttonLabel,
        color: props?.color || Theme.secondary,
        paddingTop: 2, // To compensate for font not looking centered
      }}
    >
      {props.text}
    </Text>
  </TouchableOpacity>
);

const base = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 40,
  paddingRight: 40,
  height: Theme.buttonHeight,
  borderRadius: Theme.buttonRadius,
  borderColor: "transparent",
  borderWidth: 1,
};
