import React from "react";
import { ScrollView, Text, TextInput, View } from "react-native";
import { RadioButton } from "react-native-paper";
import JustifyContent from "../components/JustifyContent";
import IconHeader from "../components/IconHeader";
import Renderer from "../helpers/renderer";
import { useGlobalState } from "../services/store";
import { Inputs, Layout, Texts, Theme } from "../styles";
import { Spacer } from "./Spacer";

const RadioFormStep = (props) => {
  const getResponsiveStyle = props.getResponsiveStyle;
  const { state, dispatch } = useGlobalState();

  const onChangeText = (value, field) => {
    const payload = { propertyname: field };
    payload[field] = value;
    dispatch({ type: "SET_FORMDATA", payload: payload });

    if (props.textChanged) {
      props.textChanged(value && value.length > 0);
    }
  };

  const setChecked = (value, field) => {
    const payload = { propertyname: field };
    payload[field] = value;
    if (props.selectionChanged) {
      props.selectionChanged(true);
    }
    dispatch({ type: "SET_FORMDATA", payload: payload });
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        ...getResponsiveStyle({
          mobile: { paddingTop: props.paddingTop, paddingBottom: 80 },
          tablet: { paddingBottom: props.paddingBottom },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            padding: 20,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            ...getResponsiveStyle({
              tablet: {
                paddingTop: 70,
                paddingRight: 70,
                paddingBottom: 20,
                paddingLeft: 70,
              },
            }),
          }}
        >
          <View
            style={{
              flex: 1,
              maxWidth: Layout.maxContent,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: -1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <IconHeader
                color={props.subchapter.color}
                image={props.subchapter.chapter_image}
                title={props.substep.title}
                chapter_keyword={props.subchapter.chapter_keyword}
              />

              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
                position="left"
              >
                {props.substep &&
                  props.substep.view_content &&
                  props.substep.view_content.map((component, index) =>
                    Renderer(component, index)
                  )}

                {props.substep &&
                  props.substep.radiochoices &&
                  props.substep.radiochoices.map((radiochoice, index) => (
                    <View
                      key={`radio_${index}`}
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexShrink: 1,
                        ...getResponsiveStyle({ mobile: { marginLeft: 20 } }),
                      }}
                    >
                      <RadioButton
                        color={Theme.textColor}
                        uncheckedColor={Theme.textColorFaded}
                        value="second"
                        status={
                          state.formdata[props.substep.radio_data_field]
                            ?.data_field === radiochoice.data_field
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() =>
                          setChecked(
                            radiochoice,
                            props.substep.radio_data_field
                          )
                        }
                      />
                      <Text
                        style={{ ...Texts.base, color: Theme.textColor }}
                        onPress={() =>
                          setChecked(
                            radiochoice,
                            props.substep.radio_data_field
                          )
                        }
                      >
                        {radiochoice.label}
                      </Text>
                    </View>
                  ))}
              </JustifyContent>
            </View>
            <View></View>
            <View>
              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
                position="left"
              >
                {props.substep.textfield_header.length > 0 && (
                  <>
                    <Text
                      style={{
                        ...Texts.bodyBold,
                        color: Theme.textColorLight,
                      }}
                    >
                      {props.substep.textfield_header}
                    </Text>
                    <Spacer size="small" />
                  </>
                )}

                {props.substep &&
                  props.substep.textplaceholders &&
                  props.substep.textplaceholders.map(
                    (textplaceholder, index) => (
                      <TextInput
                        key={`textinput_${index}`}
                        placeholder={textplaceholder.placeholder}
                        multiline={true}
                        style={{
                          ...Texts.inputText,
                          ...Inputs.inputField,
                          height: 100,
                          textAlignVertical: "top",
                        }}
                        value={state.formdata[textplaceholder.data_field]}
                        onChangeText={(e) => {
                          onChangeText(e, textplaceholder.data_field);
                        }}
                        labelStyle={Inputs.label}
                      />
                    )
                  )}
              </JustifyContent>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default RadioFormStep;
