import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import Svg, { Circle, Path, Line } from "react-native-svg";

export const SkipControl = ({ type, onPress, color }) => {
  let icon;
  if (type == "backward") {
    icon = <RewindIcon color={color} />;
  } else {
    icon = <FastForwardIcon color={color} />;
  }
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        padding: 4,
        margin: 8,
      }}
    >
      {icon}
    </TouchableOpacity>
  );
};

export const playControlSettings = {
  size: 100,
  center: 50,
  radius: 45,
  arc: 47.5,
  strokeWidth: 5,
};

export const PlayControl = ({
  type,
  bgColor,
  fgColor,
  progressArc,
  onPress,
}) => {
  let icon;
  switch (type) {
    case "play":
      icon = <PlayIcon color={fgColor} />;
      break;
    case "pause":
      icon = <PauseIcon color={fgColor} />;
      break;
    case "repeat":
    default:
      icon = (
        <Icon color={fgColor} name="repeat" size={24} type="font-awesome" />
      );
      break;
  }

  return (
    <TouchableOpacity
      style={{
        margin: 20,
      }}
      onPress={onPress}
    >
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </View>
      <Svg
        height={playControlSettings.size}
        width={playControlSettings.size}
        viewBox={`0 0 ${playControlSettings.size} ${playControlSettings.size}`}
      >
        <Path
          d={progressArc}
          stroke={fgColor}
          strokeWidth={playControlSettings.strokeWidth}
          fill="none"
          strokeLinecap="round"
        />
        <Circle
          cx={playControlSettings.center}
          cy={playControlSettings.center}
          r={playControlSettings.radius}
          fill={bgColor}
        />
      </Svg>
    </TouchableOpacity>
  );
};

const SkipIcon = ({ color }) => (
  <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <Path
      d="M17.3333 23.2885C17.3333 24.1207 18.2903 24.5888 18.9472 24.0779L28.3184 16.7892C28.8331 16.3888 28.8331 15.6108 28.3184 15.2105L18.9472 7.92179C18.2903 7.4109 17.3333 7.87899 17.3333 8.71114V23.2885Z"
      fill={color}
    />
    <Path
      d="M2.66675 23.2885C2.66675 24.1207 3.62383 24.5888 4.28069 24.0779L13.6519 16.7892C14.1666 16.3888 14.1666 15.6108 13.6519 15.2105L4.28069 7.92179C3.62383 7.4109 2.66675 7.87899 2.66675 8.71114V23.2885Z"
      fill={color}
    />
  </Svg>
);

const FastForwardIcon = ({ color }) => (
  <Svg width="30" height="30" viewBox="0 0 24 24" fill="none">
    <Path
      fill={color}
      d="m18.502 4.355-7.544 4.356V5.23a8.644 8.644 0 1 0 9.9 8.553h1.572C22.43 19.428 17.857 24 12.215 24 6.574 24 2 19.427 2 13.785c0-5.216 3.91-9.52 8.958-10.139V0l7.544 4.355zM14.646 16.45c.815 0 1.107-1.086 1.107-2.414 0-1.327-.292-2.414-1.107-2.414-.814 0-1.106 1.087-1.106 2.414 0 1.328.292 2.414 1.106 2.414zm0 1.207c-1.8 0-2.464-1.62-2.464-3.62 0-2.002.664-3.622 2.464-3.622 1.8 0 2.465 1.62 2.465 3.621 0 2.002-.664 3.621-2.465 3.621zm-5.43-5.572-1.307.724v-1.287l1.71-1.006h.956v7.04H9.217v-5.471z"
    />
  </Svg>
);

const RewindIcon = ({ color }) => (
  <Svg width="30" height="30" viewBox="0 0 24 24" fill="none">
    <Path
      fill={color}
      d="m5.929 4.355 7.543 4.356V5.23a8.644 8.644 0 1 1-9.9 8.553H2C2 19.428 6.574 24 12.215 24c5.642 0 10.215-4.573 10.215-10.215 0-5.216-3.909-9.52-8.957-10.139V0L5.929 4.355zm8.466 12.095c.814 0 1.106-1.086 1.106-2.414 0-1.327-.292-2.414-1.106-2.414-.815 0-1.107 1.087-1.107 2.414 0 1.328.292 2.414 1.107 2.414zm0 1.207c-1.8 0-2.465-1.62-2.465-3.62 0-2.002.664-3.622 2.465-3.622 1.8 0 2.464 1.62 2.464 3.621 0 2.002-.664 3.621-2.464 3.621zm-5.43-5.572-1.307.724v-1.287l1.71-1.006h.955v7.04H8.965v-5.471z"
    />
  </Svg>
);

const PlayIcon = ({ color }) => (
  <Svg
    width="36"
    height="31"
    viewBox="0 0 36 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.69657C8 0.934754 8.81781 0.452717 9.48432 0.821675L34.4195 14.6251C35.1071 15.0057 35.1071 15.9943 34.4195 16.3749L9.48431 30.1783C8.81781 30.5473 8 30.0652 8 29.3034V1.69657Z"
      fill={color}
    />
  </Svg>
);

const PauseIcon = ({ color }) => (
  <Svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Line
      x1="3.5"
      y1="3.5"
      x2="3.5"
      y2="22.5"
      stroke={color}
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Line
      x1="18.5"
      y1="3.5"
      x2="18.5"
      y2="22.5"
      stroke={color}
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
