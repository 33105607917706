import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Header from "../Header";
import i18n from "../../services/translations";
import { Texts, Theme } from "../../styles";

const Outline = (props) => {
  const outline = props.outline;
  const openChapter = props.openChapter;
  const selectedSubchapter = props.selectedSubchapter;
  const isExercise = props.isExercise || false;

  return (
    <>
      <Header position="left" level="h2">
        {isExercise ? i18n.t("outline.exercise") : i18n.t("outline.education")}
      </Header>
      <View
        style={{
          height: 1,
          backgroundColor: Theme.borderColor,
          marginBottom: 12,
          marginRight: 30,
        }}
      />
      {outline?.chapters &&
        outline.chapters.map((chapter) => (
          <View
            key={chapter.id}
            style={{
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: "auto",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Text style={Texts.chapterHeading}>{chapter.title}</Text>

            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              {chapter.subchapters &&
                chapter.subchapters.map((subchapter) => (
                  <View
                    key={subchapter.id}
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "stretch",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: 6,
                        paddingRight: 10,
                        paddingBottom: 6,
                        paddingLeft: 12,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        marginLeft: -10,
                        backgroundColor:
                          selectedSubchapter?.id === subchapter.id
                            ? Theme.secondary
                            : "transparent",
                      }}
                      onPress={() => {
                        openChapter(subchapter.id);
                      }}
                    >
                      <View
                        style={{
                          borderRadius: 4,
                          backgroundColor:
                            selectedSubchapter?.id === subchapter.id
                              ? Theme.textColorLight
                              : Theme.textColorFaded,
                          width: 8,
                          height: 8,
                          marginRight: 10,
                        }}
                      />
                      <Text
                        style={{
                          ...Texts.subChapterHeading,
                          color:
                            selectedSubchapter?.id === subchapter.id
                              ? Theme.textColorLight
                              : Theme.textColor,
                        }}
                      >
                        {subchapter.title}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ))}
            </View>
          </View>
        ))}
    </>
  );
};

export default Outline;
