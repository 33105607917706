import React from "react";
import i18n from "../services/translations";
import { PrimaryButton } from "./Buttons";

export const NextButton = ({ customProps }) => {
  const gotoNext = customProps?.gotoNext ? customProps.gotoNext : () => {};

  return (
    <PrimaryButton
      onPress={gotoNext}
      text={i18n.t("buttons.continue")}
      style={{ alignSelf: "center", marginTop: 20 }}
    />
  );
};

export default NextButton;
