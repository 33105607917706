import { Video } from "expo-av";
import { LinearGradient } from "expo-linear-gradient";
import Hls from "hls.js/dist/hls.min.js";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Animated,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { createElement } from "react-native-web";
import { describeArc, getTranslation, pad } from "../helpers/functions";
import Renderer, { isComponent } from "../helpers/renderer";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Layout, Texts, Theme } from "../styles";
import { PlayControl, playControlSettings, SkipControl } from "./VideoControl";

const VideoStep = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    endVideo,
    doneContentVisible: slideupOpen,
    showDoneContent: () => setSlideupOpen(true),
    hideDoneContent: () => setSlideupOpen(false),
    replay,
  }));

  const {
    getResponsiveStyle,
    gotoNext,
    onSkip,
    onShowDoneContent,
    onHideDoneContent,
  } = props;

  const NAV_HIDE = 5000;

  const { state, dispatch } = useGlobalState();

  const [slideupOpen, setSlideupOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [slideSize, setTheSlideSize] = useState({ width: 0, height: 0 });
  const videoPaused = useRef(false);
  const [videoDone, setVideoDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoEverStarted, setVideoEverStarted] = useState(false);
  const [progressArc, setProgressArc] = useState("");
  const [videoProgress, setVideoProgress] = useState(0.0);
  const [videoDuration, setVideoDuration] = useState(0);
  const isMounted = useRef(false);
  const [isHovering, setIsHovering] = useState(false);
  const TIMEOUT = useRef(undefined);
  const foregroundColor = useRef("pink");
  const hlsRef = useRef(undefined);
  const [videoUrl, setVideoUrl] = useState(props.substep?.url);
  const [captionLanguage, setCaptionLanguage] = useState("sv");

  const [currentCueText, setCurrentCueText] = useState("");

  const isEmpty = function (obj) {
    return (
      obj === null ||
      obj === false ||
      (Array.isArray(obj) && obj.length == 0) ||
      obj === undefined
    );
  };

  const showNavigation = () => {
    if (!videoDone) {
      setNavigationOpen(true);

      if (!isHovering && !videoPaused.current) {
        hideAfterTimeout();
      }
    }
  };

  const hideAfterTimeout = () => {
    TIMEOUT.current = setTimeout(() => {
      if (isMounted.current) {
        animateVisibility(0, () => {
          setNavigationOpen(false);
          setVideoEverStarted(true);
        });
      }
    }, NAV_HIDE);
  };

  const hideNavigation = () => {
    if (TIMEOUT.current) {
      clearTimeout(TIMEOUT.current);
      TIMEOUT.current = undefined;
    }
    if (isMounted.current) {
      animateVisibility(0, () => {
        setNavigationOpen(false);
        setVideoEverStarted(true);
      });
    }
  };

  const animateVisibility = (toValue, callback) => {
    Animated.timing(headingOpacity, {
      toValue: toValue,
      duration: 700,
      useNativeDriver: false,
    }).start(() => {
      if (isMounted.current) {
        callback();
      }
    });
  };

  const toggleNavigation = () => {
    if (navigationOpen) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };

  const hoverIn = () => {
    if (Platform.OS === "web") {
      setNavigationOpen(true);
    }
  };
  const hoverOut = () => {
    if (Platform.OS === "web") {
      setNavigationOpen(false);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (deviceWidth >= Layout.medium && props.substep.url_landscape) {
        setVideoUrl(props.substep.url_landscape);
      } else {
        setVideoUrl(props.substep.url);
      }
    }
  }, [deviceWidth]);

  useEffect(() => {
    if (!isLoading) {
      setVideoDone(false);
      setVideoEverStarted(false);
      videoPaused.current = false;
      onHideDoneContent();
      if (state.selectedIndex !== props.stepindex || !state.isInEducation) {
        stop();
      }
      if (state.selectedIndex === props.stepindex && state.isInEducation) {
        showNavigation();
        play();
      }
    }
  }, [state.selectedIndex, state.isInEducation, isLoading]);

  const [deviceWidth, setDeviceWidth] = useState(
    Dimensions.get("window").width
  );
  const [deviceHeight, setDeviceHeight] = useState(
    Dimensions.get("window").height
  );

  useEffect(() => {
    isMounted.current = true;
    const updateLayout = () => {
      setDeviceWidth(Dimensions.get("window").width);
      setDeviceHeight(Dimensions.get("window").height);
    };
    let layoutListener, metaListener, timeListener;
    layoutListener = Dimensions.addEventListener("change", updateLayout);
    if (Platform.OS === "web") {
      metaListener = video.current?.addEventListener(
        "loadedmetadata",
        function () {
          if (isLoading && isMounted.current) {
            setIsLoading(false);
          }
          setVideoDuration(Math.floor(video.current?.duration));
          _setVideoCaptions();
        }
      );
      timeListener = video.current?.addEventListener("timeupdate", function () {
        setWebVideoStatus();
      });
    }
    if (props.substep.color) {
      foregroundColor.current = props.substep.color;
    } else if (props.subchapter.color) {
      foregroundColor.current = props.subchapter.color;
    }

    _initVideoPlayer();

    return () => {
      if (metaListener) metaListener.remove();
      if (timeListener) timeListener.remove();
      layoutListener.remove();
      isMounted.current = false;
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, []);

  const _initVideoPlayer = () => {
    if (props.substep?.url && video.current) {
      if (Hls.isSupported()) {
        hlsRef.current = new Hls();
        hlsRef.current.loadSource(props.substep.url);
        hlsRef.current.attachMedia(video.current);
      } else if (
        video.current.canPlayType("application/vnd.apple.mpegurl") ||
        props.substep?.url.indexOf(".m3u8") === -1
      ) {
        video.current.src = props.substep?.url;

        video.current.load();
      }
    }
  };
  useEffect(() => {
    if (props.playStateChanged) {
      props.playStateChanged(videoDone);
    }
  }, [videoDone, videoEverStarted]);

  const slideAnimationTop = useRef(new Animated.Value(deviceHeight)).current;
  const headingOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (navigationOpen) {
      Animated.timing(headingOpacity, {
        toValue: 1,
        duration: 350,
        useNativeDriver: false,
      }).start();
    }
  }, [navigationOpen, isHovering]);

  useEffect(() => {
    if (slideupOpen) {
      Animated.timing(slideAnimationTop, {
        toValue: -slideSize.height / 2,
        duration: 340,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(slideAnimationTop, {
        toValue: deviceHeight,
        duration: 250,
        useNativeDriver: false,
      }).start();
    }
  }, [slideupOpen]);

  const _setVideoCaptions = () => {
    let textTracks = video.current?.textTracks;
    if (state.showCaptions && textTracks?.length > 0) {
      for (let i = 0; i < textTracks.length; i++) {
        let track = textTracks[i];
        if (state.showCaptions && captionLanguage == track.language) {
          track.mode = "hidden";
          if (track.activeCues?.length > 0) {
            setCurrentCueText(track.activeCues[0].text);
          }
          track.oncuechange = function () {
            if (this.activeCues?.length > 0) {
              setCurrentCueText(this.activeCues[0].text);
            } else {
              setCurrentCueText("");
            }
          };
        } else {
          track.mode = "disabled";
          track.oncuechange = null;
        }
      }
    }
  };

  useEffect(() => {
    _setVideoCaptions();
  }, [state.showCaptions, captionLanguage]);

  const toggleCaptions = () => {
    dispatch({ type: "SET_SHOWCAPTIONS", payload: !state.showCaptions });
  };

  const video = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);

  const setContainerSize = (layout) => {
    const { width } = layout;
    setContainerWidth(width);
  };
  const setSlideSize = (layout) => {
    const { width, height } = layout;
    setTheSlideSize({ width: width, height: height });
  };
  const getSlideMinWidth = () => {
    return Math.min(380, deviceWidth - 20);
  };
  const createWebVideo = (attrs) => {
    return createElement("video", attrs);
  };
  const skipVideo = () => {
    onSkip();
    endVideo();
  };
  const toggleVideo = () => {
    if (videoDone) {
      replay();
    } else {
      !videoPaused.current ? pause() : play();
    }
    if (videoPaused.current) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };
  const replayVideo = () => {
    replay();
  };
  const play = async () => {
    if (Platform.OS === "web") {
      const playPromise = video.current?.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            setVideoDone(false);
            videoPaused.current = false;
            // Automatic playback started!
            _setVideoCaptions();
          })
          .catch(function (error) {
            console.error(error);
            setVideoDone(false);
            setVideoEverStarted(false);
            videoPaused.current = true;
          });
      }
    } else {
      video.current?.playAsync();
      setVideoDone(false);
      videoPaused.current = false;
    }
  };
  const pause = async () => {
    if (Platform.OS === "web") {
      video.current?.pause();
    } else {
      video.current?.pauseAsync();
    }
    videoPaused.current = true;
  };

  const replay = async () => {
    if (Platform.OS === "web") {
      if (video.current) video.current.currentTime = 0;
      video.current?.play();
    } else {
      video.current?.replayAsync();
    }
    setVideoDone(false);
    videoPaused.current = false;
    hideNavigation();
    onHideDoneContent();
  };

  const jump = (seconds) => {
    if (!video.current) {
      return;
    }

    const currentTime = video.current.currentTime;
    video.current.currentTime = currentTime + seconds;
  };
  const stop = async () => {
    if (Platform.OS === "web") {
      if (video.current) video.current.currentTime = 0;
      video.current?.pause();
    } else {
      video.current?.stopAsync();
    }

    videoPaused.current = true;
  };

  const endVideo = () => {
    setVideoDone(true);
    pause();
    if (!isEmpty(props.substep.done_content)) {
      onShowDoneContent();
      hideNavigation();
    } else if (props.substep.continue_automatically) {
      gotoNext();
    } else {
      showNavigation();
    }
  };
  const getAdjustedHeight = () => {
    if (deviceWidth >= Layout.medium) {
      return { height: containerWidth * 0.5625 }; //16:9
    }
    return {};
  };
  const setWebVideoStatus = () => {
    if (!isMounted.current) {
      return;
    }
    if (isMounted.current) {
      if (video.current?.currentTime > 0) {
        if (video.current?.ended) {
          endVideo();
        }

        const millis = video.current?.duration - video.current?.currentTime;
        const minutes = Math.floor(millis / 60);
        const seconds = pad(Math.floor(millis - minutes * 60), 2);

        const progress = `${minutes}:${seconds}`;

        setVideoProgress(progress);
        const progArc = describeArc(
          playControlSettings.center,
          playControlSettings.center,
          playControlSettings.arc,
          0,
          (video.current?.currentTime / video.current?.duration) * 360
        );
        setProgressArc(progArc);
      } else {
        setProgressArc("");
      }
    }
  };

  const setVideoStatus = (status) => {
    if (isMounted.current) {
      if (status.isLoaded && isLoading) {
        setIsLoading(false);

        video.current?.setProgressUpdateIntervalAsync(1000);
      }
      if (status.durationMillis > 0) {
        setVideoDuration(status.durationMillis);
        if (status.positionMillis === status.durationMillis) {
          setVideoDone(true);
          if (!isEmpty(props.substep.done_content)) {
            onShowDoneContent();
          } else if (props.substep.continue_automatically) {
            gotoNext();
          } else {
            showNavigation();
          }
        }

        const millis = status.durationMillis - status.positionMillis;
        const minutes = Math.floor(millis / 60000);
        const seconds = pad(Math.floor(millis / 1000 - minutes * 60), 2);

        const progress = `${minutes}:${seconds}`;

        setVideoProgress(progress);
        const progArc = describeArc(
          playControlSettings.center,
          playControlSettings.center,
          playControlSettings.arc,
          0,
          (status.positionMillis / status.durationMillis) * 360
        );
        setProgressArc(progArc);
      } else {
        setProgressArc("");
      }
    }
  };

  const getVideoStyle = () => {
    if (props.substep?.force_landscape) {
      return {
        objectFit: "contain",
      };
    } else {
      return {
        height: "100vh",
        objectFit: "cover",
      };
    }
  };

  const showControls =
    (videoEverStarted && (navigationOpen || videoPaused.current)) ||
    (!videoEverStarted &&
      !slideupOpen &&
      isEmpty(props.substep.before_content));

  const interpolationStyles = {
    playButton: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: showControls ? Theme.videoOverlay : "transparent",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 40,
    },
    slideContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: [
        { translateX: -slideSize.width / 2 },
        { translateY: slideAnimationTop },
      ],
    },
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        ...getResponsiveStyle({
          tablet: {
            paddingBottom: props.paddingBottom,
            marginLeft: 60,
            margin: 20,
          },
        }),
      }}
    >
      <View style={{ flex: 1, maxWidth: Layout.maxContent }}>
        {deviceWidth < Layout.medium && (
          <>
            <LinearGradient
              // Background Linear Gradient
              colors={[
                "rgba(60,60,60,0.65)",
                "rgba(60,60,60,0.2)",
                "rgba(60,60,60,0.01)",
              ]}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: 100,
                zIndex: 10,
              }}
            />
            <LinearGradient
              // Background Linear Gradient
              colors={[
                "rgba(60,60,60,0.0)",
                "rgba(60,60,60,0.2)",
                "rgba(60,60,60,0.7)",
              ]}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 100,
                zIndex: 10,
              }}
            />
          </>
        )}
        <TouchableWithoutFeedback onPress={toggleNavigation}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              position: "relative",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
            onMouseEnter={hoverIn}
            onMouseLeave={hoverOut}
          >
            <View
              style={{
                flex: 2,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onLayout={(event) => {
                setContainerSize(event.nativeEvent.layout);
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "stretch",
                  ...getAdjustedHeight(),
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: props.substep.force_landscape
                      ? "center"
                      : "space-between",
                    position: "relative",
                    alignItems: "stretch",
                  }}
                >
                  {Platform.OS === "web" ? (
                    createWebVideo({
                      ref: video,
                      preload: "metadata",
                      autoPlay: true,
                      playsInline: true,
                      poster:
                        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                      style: {
                        width: "100%", //fixes Safari issue
                        ...getVideoStyle(),
                        ...getAdjustedHeight(),
                        margin: 0,
                        padding: 0,
                      },
                    })
                  ) : (
                    <Video
                      ref={video}
                      style={{
                        alignSelf: "stretch",
                        height: Platform.OS === "web" ? "100vh" : "100%",
                      }}
                      isMuted
                      useNativeControls={false}
                      resizeMode="cover"
                      onPlaybackStatusUpdate={(status) =>
                        setVideoStatus(status)
                      }
                    />
                  )}

                  <Animated.View
                    style={{
                      ...interpolationStyles.playButton,
                      opacity: headingOpacity, // Bind opacity to animated value
                    }}
                  >
                    <View
                      style={{
                        flex: -1,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ position: "relative" }}>
                        {!videoEverStarted &&
                          !isEmpty(props.substep.before_content) && (
                            <View>
                              <View
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {props.substep.before_content.map(
                                  (component, index) =>
                                    Renderer(component, index)
                                )}
                              </View>
                              <Text
                                style={{
                                  ...Texts.h2,
                                  color: Theme.textColorLight,
                                  textAlign: "center",
                                  margin: 20,
                                  marginTop: 40,
                                }}
                              >
                                {getTranslation(props.substep.title, state)}
                              </Text>
                            </View>
                          )}

                        {showControls && (
                          <View
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                ...Texts.h2,
                                color: Theme.textColorLight,
                                textAlign: "center",
                                margin: 20,
                              }}
                            >
                              {getTranslation(props.substep.title, state)}
                            </Text>

                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SkipControl
                                type="backward"
                                onPress={() => jump(-10)}
                                color={Theme.skipControlBg}
                              />
                              <PlayControl
                                type={
                                  videoDone
                                    ? "repeat"
                                    : !videoPaused.current
                                    ? "pause"
                                    : "play"
                                }
                                bgColor={Theme.videoControlBg}
                                fgColor={Theme.videoControlFg}
                                progressArc={
                                  videoDone ? undefined : progressArc
                                }
                                onPress={toggleVideo}
                              />
                              <SkipControl
                                type="forward"
                                onPress={() => jump(10)}
                                color={Theme.skipControlBg}
                              />
                            </View>

                            {!isEmpty(props.substep.done_content) &&
                              props.substep.done_content.length > 0 && (
                                <TouchableOpacity onPress={skipVideo}>
                                  <Text
                                    style={{
                                      marginTop: 10,
                                      ...Texts.buttonLabel,
                                      color: Theme.textColorLight,
                                      textAlign: "center",
                                    }}
                                  >
                                    {i18n.t("buttons.skip")}
                                  </Text>
                                </TouchableOpacity>
                              )}
                          </View>
                        )}
                      </View>
                    </View>
                  </Animated.View>
                </View>

                {!slideupOpen &&
                  state.showCaptions &&
                  deviceWidth >= Layout.medium && (
                    <View
                      pointerEvents={"box-none"}
                      style={{
                        bottom: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          ...Texts.subTitle,
                          backgroundColor: Theme.subTitleBg,
                          color: Theme.subTitleColor,
                          display:
                            currentCueText && currentCueText.length > 0
                              ? "flex"
                              : "none",
                          padding: 9,
                          paddingBottom: 7,
                          zIndex: 30,
                          textAlign: "center",
                          maxWidth: "60%",
                        }}
                      >
                        {currentCueText}
                      </Text>
                    </View>
                  )}

                {false && deviceWidth >= Layout.medium && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      bottom: 10,
                      paddingRight: 10,
                      justifyContent: "flex-end",
                      zIndex: 40,
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        borderRadius: 40,
                        backgroundColor: Theme.secondary,
                        height: 40,
                        width: 40,
                        flex: -1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 40,
                      }}
                      onPress={toggleCaptions}
                    >
                      <Icon
                        color={Theme.textColorLight}
                        name="speech"
                        size={20}
                        type="simple-line-icon"
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
            {!slideupOpen && state.showCaptions && deviceWidth < Layout.medium && (
              <View
                pointerEvents={"box-none"}
                style={{
                  bottom: 125,
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    ...Texts.subTitle,
                    backgroundColor: Theme.subTitleBg,
                    color: Theme.subTitleColor,
                    display:
                      currentCueText && currentCueText.length > 0
                        ? "flex"
                        : "none",
                    padding: 9,
                    paddingBottom: 7,
                    zIndex: 30,
                    textAlign: "center",
                    maxWidth: "80%",
                  }}
                >
                  {currentCueText}
                </Text>
              </View>
            )}

            {false && deviceWidth < Layout.medium && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  bottom: 80,
                  justifyContent: "center",
                  zIndex: 40,
                  width: "100%",
                  position: "absolute",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 40,
                    backgroundColor: Theme.secondary,
                    height: 40,
                    width: 40,
                    flex: -1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 40,
                  }}
                  onPress={toggleCaptions}
                >
                  <Icon
                    color={Theme.textColorLight}
                    name="speech"
                    size={20}
                    type="simple-line-icon"
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
        <Animated.View style={interpolationStyles.slideContainer}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 20,
            }}
            onLayout={(event) => {
              setSlideSize(event.nativeEvent.layout);
            }}
          >
            <View
              style={{
                backgroundColor: Theme.modalBg,
                padding: 32,
                borderRadius: 12,
                flex: 1,
                position: "relative",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
                minWidth: getSlideMinWidth(),
                width: "80%",
              }}
            >
              {!isEmpty(props.substep.done_content) &&
                props.substep.done_content.length > 0 &&
                props.substep.done_content.map((component, index) => {
                  if (isComponent(component, "show_next_button")) {
                    return Renderer(component, index, undefined, undefined, {
                      buttonColor: Theme.primary,
                      gotoNext: gotoNext,
                    });
                  } else {
                    return Renderer(component, index);
                  }
                })}
            </View>
            <TouchableOpacity onPress={replayVideo}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    ...Texts.playAgain,
                    color: Theme.textColorLight,
                    marginTop: 0,
                    padding: 20,
                  }}
                >
                  {i18n.t("buttons.playagain")}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </View>
    </View>
  );
});

export default VideoStep;
