import React from "react";
import { StyleSheet, Text, View } from "react-native";
import i18n from "../../services/translations";
import { Texts, Theme } from "../../styles";
import { PrimaryButton } from "../Buttons";
import { Spacer } from "../Spacer";
import { ResultViewSection } from "./ResultViewSection";
import { useResultData } from "./useResultData";

const _getResponsiveStyle = () => {
  return {};
};

const ResultView = (props) => {
  const resultData = useResultData();
  const getResponsiveStyle = props.getResponsiveStyle ?? _getResponsiveStyle;

  return (
    <View
      style={[
        styles.container,
        getResponsiveStyle({
          mobile: {
            borderWidth: 0,
            borderRadius: 0,
            backgroundColor: "transparent",
          },
        }),
      ]}
    >
      <View
        style={[
          styles.header,
          getResponsiveStyle({
            mobile: {
              borderRadius: 5,
            },
          }),
        ]}
      >
        <Text style={styles.headerText}>{i18n.t("labels.action_plan")}</Text>
      </View>
      <View
        style={[
          styles.content,
          getResponsiveStyle({
            mobile: {
              paddingBottom: 0,
              paddingStart: 0,
              paddingEnd: 0,
            },
          }),
        ]}
      >
        {resultData.map((section, index) => (
          <React.Fragment key={index}>
            <ResultViewSection section={section} />
            <Spacer size="small" />
          </React.Fragment>
        ))}
        <PrimaryButton
          nativeID="print-button"
          onPress={() => window?.print()}
          text={i18n.t("buttons.print")}
          style={{ backgroundColor: Theme.secondary }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: Theme.resultViewBorderColor,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: Theme.modalBg,
  },
  header: {
    height: 55,
    backgroundColor: Theme.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    ...Texts.resultBoxHeading,
    color: Theme.textColorLight,
  },
  content: {
    display: "flex",
    alignItems: "center",
    paddingTop: 30,
    paddingBottom: 30,
    paddingStart: 40,
    paddingEnd: 40,
  },
});

export default ResultView;
