import React, { useCallback, useEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import IconHeader from "../components/IconHeader";
import JustifyContent from "../components/JustifyContent";
import ParsedText from "react-native-parsed-text";
import Renderer from "../helpers/renderer";
import { getTranslation } from "../helpers/functions";
import { EMOTIONMAP, getColorizedEmotion } from "../helpers/svg-assets";
import { useGlobalState } from "../services/store";
import { Layout, Texts, Theme } from "../styles";
import { buttonLabel } from "../styles/texts";
import {
  CustomEmotionModal,
  CustomEmotionModalButton,
} from "./CustomEmotionModal";
import { Spacer } from "./Spacer";

const matchedText = (matchingString, matches) => {
  if (matches.length > 1) {
    return matches[1];
  } else {
    return matchingString;
  }
};

const Emotion = ({ title, image, isSelected, onSelect }) => (
  <TouchableOpacity
    style={{
      display: "inline-flex",
      alignItems: "center",
      width: `${(1 / 3) * 100}%`,
      marginBottom: 32,
      overflow: "hidden",
    }}
    onPress={onSelect}
  >
    <View
      style={{
        height: 55,
        width: 55,
        marginBottom: 12,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {image}
    </View>
    <View
      style={{
        justifyContent: "center",
        borderRadius: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 1,
        paddingBottom: 1,
        borderWidth: 1,
        maxWidth: "100%",
        backgroundColor: isSelected ? Theme.primary : undefined,
        borderColor: isSelected ? Theme.primary : Theme.secondary,
      }}
    >
      <Text
        style={{
          ...buttonLabel,
          paddingTop: 2,
          color: isSelected ? Theme.textColorLight : Theme.secondary,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontSize: 12,
        }}
      >
        {title}
      </Text>
    </View>
  </TouchableOpacity>
);

const sortEmotions = (baseEmotions) => {
  const emotions = baseEmotions
    .map((baseEmotion) =>
      baseEmotion.emotions.map((emotion) => ({
        ...emotion,
        baseEmotionId: baseEmotion.id,
      }))
    )
    .flat();

  return emotions.sort((a, b) => a.index - b.index);
};

const EmotionStep = (props) => {
  const getResponsiveStyle = props.getResponsiveStyle;
  const { state, dispatch } = useGlobalState();
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);
  const [customEmotion, setCustomEmotion] = useState(
    state.formdata.customemotion
      ? {
          customEmotion: state.formdata.customemotion,
          baseEmotionId: state.formdata.baseemotion,
        }
      : undefined
  );

  useEffect(() => {
    const data = state.formdata;
    const isDone =
      data.baseemotion && (data.emotion || data.customemotion) ? true : false;
    props.emotionSelectedChanged(isDone);
  }, [state.formdata, props.emotionSelectedChanged]);

  const updateFormData = useCallback(
    ({ baseEmotionId, emotionId, customEmotion }) => {
      dispatch({
        type: "SET_FORMDATA",
        payload: {
          baseemotion: baseEmotionId,
          propertyname: "baseemotion",
        },
      });

      if (customEmotion) {
        dispatch({
          type: "SET_FORMDATA",
          payload: {
            customemotion: customEmotion,
            propertyname: "customemotion",
          },
        });

        dispatch({
          type: "SET_FORMDATA",
          payload: {
            emotion: undefined,
            propertyname: "emotion",
          },
        });
      } else if (emotionId) {
        dispatch({
          type: "SET_FORMDATA",
          payload: {
            emotion: emotionId,
            propertyname: "emotion",
          },
        });

        dispatch({
          type: "SET_FORMDATA",
          payload: {
            emotion: undefined,
            propertyname: "customemotion",
          },
        });
      }

      props.emotionSelectedChanged(true);
    },
    [dispatch, props.emotionSelectedChanged]
  );

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        paddingTop: props.paddingTop,
        ...getResponsiveStyle({
          mobile: { paddingBottom: 80 },
          tablet: {
            paddingBottom: props.paddingBottom,
            paddingLeft: 50,
          },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            padding: 20,
            ...getResponsiveStyle({ mobile: { paddingTop: 20 } }),
          }}
        >
          <View
            style={{
              flex: 1,
              maxWidth: Layout.maxContent,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: -1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <IconHeader
                color={props.subchapter.color}
                image={props.subchapter.chapter_image}
                title={props.substep.title}
                chapter_keyword={props.subchapter.chapter_keyword}
              />

              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
              >
                {props.substep &&
                  props.substep.view_content &&
                  props.substep.view_content.map((component, index) =>
                    Renderer(component, index)
                  )}

                <Spacer size="medium" />

                <View
                  style={{
                    ...getResponsiveStyle({
                      mobile: { flexDirection: "column", flex: 1 },
                      tablet: { flexDirection: "row", paddingTop: 24 },
                    }),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      ...getResponsiveStyle({
                        mobile: { width: "100%" },
                        tablet: { width: "70%" },
                      }),
                    }}
                  >
                    <CustomEmotionModalButton
                      onPress={() => setCustomModalIsOpen(true)}
                    />
                    <CustomEmotionModal
                      isOpen={customModalIsOpen}
                      onClose={() => setCustomModalIsOpen(false)}
                      onDone={({ customEmotion, baseEmotionId }) => {
                        setCustomEmotion({ customEmotion, baseEmotionId });
                        updateFormData({ baseEmotionId, customEmotion });
                        setCustomModalIsOpen(false);
                      }}
                    />

                    {customEmotion ? (
                      <Emotion
                        title={customEmotion.customEmotion}
                        isSelected={
                          customEmotion.customEmotion ===
                          state.formdata.customemotion
                        }
                        onSelect={() => updateFormData(customEmotion)}
                        image={getColorizedEmotion(
                          customEmotion.baseEmotionId,
                          customEmotion.customEmotion ===
                            state.formdata.customemotion
                            ? Theme.primary
                            : Theme.secondary
                        )}
                      />
                    ) : null}

                    {sortEmotions(state.baseemotions).map((emotion) => (
                      <Emotion
                        key={emotion.id}
                        title={emotion.title}
                        image={getColorizedEmotion(
                          emotion.baseEmotionId,
                          emotion.id === state.formdata.emotion
                            ? Theme.primary
                            : Theme.secondary
                        )}
                        isSelected={emotion.id === state.formdata.emotion}
                        onSelect={() =>
                          updateFormData({
                            emotionId: emotion.id,
                            baseEmotionId: emotion.baseEmotionId,
                          })
                        }
                      />
                    ))}
                  </View>
                </View>
              </JustifyContent>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default EmotionStep;
