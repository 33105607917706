import { useGlobalState } from "../../services/store";
import { Theme } from "../../styles";

export const useResultData = () => {
  const { state } = useGlobalState();
  const { exercise, formdata: formData, baseemotions: baseEmotions } = state;

  let sections = [];

  if (!exercise || !formData || !baseEmotions) {
    return sections;
  }

  exercise.chapters?.forEach((chapter) => {
    chapter.subchapters.forEach((subchapter) => {
      let section = {
        title: subchapter.title,
        image: subchapter.chapter_image,
        color: subchapter.color?.length ? subchapter.color : Theme.borderColor,
        subSections: [],
      };

      subchapter.substeps.forEach((substep) => {
        const subTitle = substep.result_view_question_intro.length
          ? substep.result_view_question_intro
          : substep.title;

        switch (substep.type) {
          case "freetext": {
            if (!substep.textplaceholders.length) {
              break;
            }
            section.subSections.push({
              subTitle,
              content: substep.textplaceholders.map((textPlaceholder) => {
                const answer = formData[textPlaceholder.data_field];
                return answer?.length ? answer : "-";
              }),
            });
            break;
          }

          case "radio": {
            const answer = formData[substep.radio_data_field]?.label;
            section.subSections.push({
              subTitle,
              content: [answer?.length ? answer : "-"],
            });
            break;
          }

          case "range": {
            const rangeFormData = formData[substep.radio_data_field];
            if (!rangeFormData) {
              break;
            }
            const choices = rangeFormData.choices;
            const choiceIndex = Math.round(
              (rangeFormData.value / 100) * choices.length - 1
            );
            const answerLabel = choices[choiceIndex];
            section.subSections.push({
              subTitle,
              content: [answerLabel],
            });
            break;
          }

          case "emotion": {
            const baseEmotion = baseEmotions.find(
              (baseEmotion) => baseEmotion.id === formData.baseemotion
            );
            if (!baseEmotion) {
              break;
            }
            const answer =
              formData.customemotion ??
              baseEmotion.emotions.find(
                (emotion) => emotion.id === formData.emotion
              )?.title;
            section.subSections.push({
              subTitle,
              emotionId: baseEmotion.id,
              content: [answer?.length ? answer : "-"],
            });
            break;
          }

          default: {
            break;
          }
        }
      });

      if (section.subSections.length > 0) {
        sections.push(section);
      }
    });
  });

  return sections;
};
