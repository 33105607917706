import React from "react";
import { Text, View } from "react-native";
import { Texts, Theme } from "../../styles";
import { SecondaryButton } from "../Buttons";
import ValuePicker from "./ValuePicker";

const SingleChoiceQuestion = ({
  question,
  answers,
  setAnswer,
  getResponsiveStyle,
}) => {
  const options = question.data.options;
  const value = answers[question.airtableField];

  return (
    <>
      <Text
        style={{
          ...Texts.surveyText,
          color: Theme.textColor,
          marginBottom: 20,
        }}
      >
        {question.description}
      </Text>
      <View
        style={{
          display: "flex",
          ...getResponsiveStyle({ mobile: { display: "none" } }),
        }}
      >
        <ValuePicker
          withDividers
          options={options}
          value={value}
          onChange={(option) =>
            setAnswer({ id: question.airtableField, value: option.value })
          }
        />
      </View>

      <View
        style={{
          display: "none",
          ...getResponsiveStyle({ mobile: { display: "flex" } }),
        }}
      >
        {question.data.options.map((option) => {
          const isSelected = option.value === value;

          return (
            <SecondaryButton
              key={option.value}
              text={option.label}
              style={{
                backgroundColor: isSelected ? Theme.primary : "transparent",
                marginBottom: 10,
              }}
              color={isSelected ? Theme.textColorLight : Theme.primary}
              onPress={() =>
                setAnswer({ id: question.airtableField, value: option.value })
              }
            />
          );
        })}
      </View>
    </>
  );
};

export default SingleChoiceQuestion;
