import React from "react";
import { View } from "react-native";

const CenteredContent = ({
  children,
  minWidth,
  maxWidth,
  alignTop = false,
  active = true,
}) => {
  const emptyStyle = {};
  const activeOuterStyle = {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: alignTop ? "flex-start" : "center",
  };
  const activeInnerStyle = {
    flex: 1,
    flexShrink: 1,
    maxWidth: maxWidth,
    minWidth: minWidth,
    flexDirection: "column",
    justifyContent: "flex-start",
  };
  return (
    <View style={active ? activeOuterStyle : emptyStyle}>
      <View style={active ? activeInnerStyle : emptyStyle}>{children}</View>
    </View>
  );
};
export default CenteredContent;
