import React from "react";
import ParsedText from "react-native-parsed-text";
import { Texts, Theme } from "../../styles";
import ValuePicker from "./ValuePicker";

const RatingScaleQuestion = ({ question, answers, setAnswer }) => {
  const { range, labels } = question.data;
  const options = useOptions(range);

  return (
    <>
      <ParsedText
        style={{
          ...Texts.surveyText,
          color: Theme.textColor,
          marginBottom: 20,
        }}
        parse={[
          {
            pattern: /{light:([^}]+)}/i,
            style: { fontFamily: Texts.FONT_FAMILYS.BODY_LIGHT },
            renderText: (matchingString, matches) => {
              if (matches.length > 1) {
                return matches[1];
              } else {
                return matchingString;
              }
            },
          },
        ]}
      >
        {question.description}
      </ParsedText>
      <ValuePicker
        options={options}
        labels={labels}
        value={answers[question.airtableField]}
        onChange={(option) =>
          setAnswer({ id: question.airtableField, value: option.value })
        }
      />
    </>
  );
};

const useOptions = (range) => {
  const numberOfOptions = range.max - range.min + 1;

  return [...Array(numberOfOptions).keys()].map((_value) => {
    const value = _value + range.min;

    return {
      value,
      label: `${value}`,
    };
  });
};

export default RatingScaleQuestion;
