import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Texts, Theme } from "../../styles";

const ValuePicker = ({ options, labels, value, onChange, withDividers }) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.picker}>
        {options.map((option, i) => {
          const isSelected = value === option.value;
          const showDivider = withDividers && i < options.length - 1;

          return (
            <TouchableOpacity
              key={option.value}
              style={{
                ...styles.pickerOption,
                backgroundColor: isSelected ? Theme.primary : "transparent",
                borderRightWidth: showDivider ? 1 : 0,
              }}
              onPress={() => onChange(option)}
            >
              <Text
                style={{
                  ...Texts.surveyQuestionOption,
                  color: isSelected ? Theme.textColorLight : Theme.primary,
                }}
              >
                {option.label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      {labels && (
        <View style={styles.labels}>
          <Text
            style={{
              ...Texts.surveyQuestionOptionLabel,
              color: Theme.textColor,
              opacity: 0.5,
            }}
          >
            {labels.min}
          </Text>
          <Text
            style={{
              ...Texts.surveyQuestionOptionLabel,
              color: Theme.textColor,
              opacity: 0.5,
            }}
          >
            {labels.max}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = {
  wrapper: {
    marginBottom: 20,
  },
  picker: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
    marginBottom: 20,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: Theme.primary,
    borderRadius: 50,
  },
  pickerOption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    borderColor: Theme.primary,
  },
  labels: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
  },
};

export default ValuePicker;
