import React, { createContext, useContext, useState } from "react";

const SurveyContext = createContext({
  isSurveyOpen: false,
  setIsSurveyOpen: () => {},
  surveySection: undefined,
  setSurveySection: () => {},
});

export const SurveyProvider = (props) => {
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [surveySection, setSurveySection] = useState(undefined);

  const value = {
    isSurveyOpen,
    setIsSurveyOpen,
    surveySection,
    setSurveySection,
  };

  return <SurveyContext.Provider value={value} {...props} />;
};

export const useSurveyContext = () => useContext(SurveyContext);
