import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";
import "react-native-gesture-handler";
import CustomHeader from "../components/CustomHeader";
import PrintView from "../components/Result/PrintView";
import i18n from "../services/translations";
import Education from "./Education";
import EducationStep from "./EducationStep";
import Home from "./Home";
import Profile from "./Profile";
import Register from "./Register";

const linking = {
  prefixes: ["https://insightgap.app/", "insightgap://"],
  config: {
    screens: {
      Main: {
        screens: {
          Home: "home",
          Register: "register/:registrationtoken?",
          Education: "education",
          Profile: "profile",
        },
      },
      Exercise: "education/exercise/:step?",
      Chapter: "education/chapter/:step?",
    },
  },
};

const MainStack = createStackNavigator();

const outlinetitle = i18n.t("appTitle", {
  appName: i18n.t("appName"),
  view: i18n.t(`navigation.outline`),
});
const MainNavigation = (props) => {
  return (
    <MainStack.Navigator
      initialRouteName="Education"
      screenOptions={{
        animationEnabled: true,
        headerTransparent: true,
        headerMode: "screen",
        header: (props) => <CustomHeader {...props} />,
      }}
    >
      <MainStack.Screen
        name="Education"
        initialParams={props.params}
        component={Education}
        options={{
          gestureEnabled: false,
          title: outlinetitle,
        }}
      />
      <MainStack.Screen
        name="Home"
        component={Home}
        initialParams={props.params}
        options={({ route, navigation }) => ({
          title: i18n.t("navigation.home"),
        })}
      />
      <MainStack.Screen
        name="Register"
        component={Register}
        options={{
          title: i18n.t("navigation.register"),
        }}
      />
      <MainStack.Screen
        name="Profile"
        component={Profile}
        options={{
          title: i18n.t("navigation.profile"),
        }}
      />
    </MainStack.Navigator>
  );
};
const Stack = createStackNavigator();
const educationtitle = i18n.t("appTitle", {
  appName: i18n.t("appName"),
  view: i18n.t(`navigation.education`),
});
const exercisetitle = i18n.t("appTitle", {
  appName: i18n.t("appName"),
  view: i18n.t(`navigation.exercise`),
});
const Navigation = (props) => {
  return (
    <NavigationContainer linking={linking}>
      <View nativeID="print-view">
        <PrintView />
      </View>
      <Stack.Navigator
        mode="modal"
        screenOptions={{
          animationEnabled: true,
        }}
      >
        <Stack.Screen
          name="Main"
          component={MainNavigation}
          options={{ headerShown: false, title: i18n.t("appName") }}
        />
        <Stack.Screen
          name="Chapter"
          component={EducationStep}
          initialParams={{ ...props.params }}
          options={{ headerShown: false, title: educationtitle }}
        />
        <Stack.Screen
          name="Exercise"
          component={EducationStep}
          initialParams={{ ...props.params }}
          options={{ headerShown: false, title: exercisetitle }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
