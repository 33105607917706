import React from "react";
import HybridApp from "./src/App";
import { SafeAreaProvider } from "react-native-safe-area-context";
const App = (props) => {
  return (
    <SafeAreaProvider>
      <HybridApp />
    </SafeAreaProvider>
  );
};
export default App;
