import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Texts } from "../styles";
import { Spacer } from "./Spacer";

function Header(props) {
  const text = props.data?.header ? props.data.header : props.children;
  const propStyles = {};

  if (props.position) {
    if (props.position == "center") propStyles.textAlign = "center";
    if (props.position == "right") propStyles.textAlign = "right";
    if (props.position == "left") propStyles.textAlign = "left";
  }

  if (props.style?.textAlign) {
    propStyles.textAlign = props.style.textAlign;
  }

  let textStyle = Texts.h1;

  if (props.level) {
    textStyle = Texts[props.level];
  }

  let externalstyle = {};

  if (props.style) {
    externalstyle = props.style;
  }

  return (
    <View style={propStyles}>
      <Text style={StyleSheet.flatten([textStyle, externalstyle])}>{text}</Text>
      <Spacer size="small" />
    </View>
  );
}

export default Header;
