import Slider from "@react-native-community/slider";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import Accordion from "../components/Accordion";
import IconHeader from "../components/IconHeader";
import JustifyContent from "../components/JustifyContent";
import Renderer from "../helpers/renderer";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Layout, Texts, Theme } from "../styles";

const RangeStep = (props) => {
  const getResponsiveStyle = props.getResponsiveStyle;
  const { state, dispatch } = useGlobalState();

  const setRangeChanged = (value, field) => {
    const choices = props.substep.radiochoices.map((radiochoice, index) => {
      return radiochoice.label;
    });
    const payload = { propertyname: field };
    payload[field] = { value: value, choices: choices };
    dispatch({ type: "SET_FORMDATA", payload: payload });
    if (props.rangeChanged) {
      props.rangeChanged(true);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        padding: 20,
        ...getResponsiveStyle({
          mobile: { paddingTop: props.paddingTop, paddingBottom: 80 },
          tablet: {
            paddingBottom: props.paddingBottom,
            paddingTop: 70,
            paddingLeft: 70,
          },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            ...getResponsiveStyle({ mobile: { paddingTop: 20 } }),
          }}
        >
          <View
            style={{
              flex: 1,
              maxWidth: Layout.maxContent,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: -1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <IconHeader
                color={props.subchapter.color}
                image={props.subchapter.chapter_image}
                title={props.substep.title}
                chapter_keyword={props.subchapter.chapter_keyword}
              />
              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
              >
                {props.substep &&
                  props.substep.view_content &&
                  props.substep.view_content.map((component, index) =>
                    Renderer(component, index)
                  )}
                {props.substep.help_content.length > 0 && (
                  <Accordion open={false} text={i18n.t("buttons.showmore")}>
                    {props.substep.help_content.map((component, index) =>
                      Renderer(component, index)
                    )}
                  </Accordion>
                )}
              </JustifyContent>
            </View>

            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "stretch",
              }}
            >
              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
              >
                <Slider
                  allowTouchTrack={true}
                  minimumValue={0}
                  maximumValue={100}
                  onSlidingComplete={(value) =>
                    setRangeChanged(value, props.substep?.radio_data_field)
                  }
                  value={
                    state.formdata[props.substep?.radio_data_field]?.value ?? 50
                  }
                  minimumTrackTintColor={Theme.borderColor}
                  maximumTrackTintColor={Theme.borderColor}
                  trackHeight={2}
                  thumbStyle={{
                    height: Theme.rangeThumbSize,
                    width: Theme.rangeThumbSize,
                    borderRadius: Theme.rangeThumbSize / 2,
                    borderColor: Theme.pageBg,
                    borderWidth: 2,
                    backgroundColor: Theme.rangeThumbBg,
                  }}
                />
                <View
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    columnGap: 20,
                  }}
                >
                  {props.substep &&
                    props.substep.radiochoices &&
                    props.substep.radiochoices.map((radiochoice, index) => {
                      const showLabel =
                        index === 0 ||
                        index === props.substep.radiochoices.length - 1;
                      return (
                        showLabel && (
                          <Text
                            key={`radio_${index}`}
                            style={{
                              ...Texts.rangeLabel,
                              textAlign: index > 0 ? "right" : "left",
                            }}
                          >
                            {radiochoice.label}
                          </Text>
                        )
                      );
                    })}
                </View>
              </JustifyContent>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default RangeStep;
