import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import i18n from "../services/translations";
import { useGlobalState } from "../services/store";

import { PrimaryButton, SecondaryButton } from "./Buttons";
import { Colors } from "../styles/colors";
import { useResponsive } from "../helpers/functions";

export const StartButtons = ({ restart, customProps }) => {
  const { state, dispatch } = useGlobalState();
  const navigation = useNavigation();
  const goto = customProps?.goto || (() => {});
  const { getResponsiveStyle } = useResponsive();

  const openCurrentChapter = () => {
    if (
      restart === true ||
      state.selectedIndex === undefined ||
      state.selectedIndex === null
    ) {
      goto(0);
    } else {
      navigation.navigate("Chapter", { step: state.selectedIndex });
    }
  };

  const containerStyle = getResponsiveStyle({
    mobile: { flexDirection: "column", flexBasis: "auto", flexGrow: 1 },
    tablet: { flexDirection: "row" },
  });

  const buttonStyle = {
    ...getResponsiveStyle({
      mobile: {
        alignSelf: "stretch",
        marginLeft: 10,
      },
      tablet: {},
    }),
    flex: 1,
    flexBasis: "auto",
    flexGrow: 0,
  };

  return (
    <View
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <PrimaryButton
        onPress={openCurrentChapter}
        color={Colors.darkBlue}
        style={{
          marginRight: 10,
          marginBottom: 16,
          ...buttonStyle,
        }}
        text={
          restart === true ? i18n.t("buttons.restart") : i18n.t("buttons.begin")
        }
        disabled={state.outline?.chapters?.length === 0}
      />

      <SecondaryButton
        onPress={() => {
          navigation.navigate("Exercise", { step: 0 });
        }}
        style={{
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 20,
          ...buttonStyle,
        }}
        text={i18n.t("buttons.directtoexercise")}
      />
    </View>
  );
};

export default StartButtons;
