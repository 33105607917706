import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import React, { createContext, useContext, useEffect, useReducer } from "react";

const TRACKER_URL = "https://visit.insightgap.com/view/matomo.php";
const SITE_ID = "2";
const Reducer = (state, action) => {
  let newState = {};
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
        isLoggedIn: action.payload !== null && action.payload !== undefined,
      };
    case "SET_PUBLIC_CONTENT":
      return {
        ...state,
        publiccontent: action.payload,
      };
    case "SET_PROTECTED_CONTENT":
      return {
        ...state,
        protectedcontent: action.payload,
      };
    case "SET_OUTLINE":
      return {
        ...state,
        outline: action.payload,
      };
    case "SET_SUBSTEPS":
      return {
        ...state,
        substeps: action.payload,
      };
    case "SET_EXERCISE":
      return {
        ...state,
        exercise: action.payload,
      };
    case "SET_EXERCISE_SUBSTEPS":
      return {
        ...state,
        exercise_substeps: action.payload,
      };
    case "SET_EMOTIONS":
      return {
        ...state,
        baseemotions: action.payload,
      };
    case "SET_SHOWCAPTIONS":
      return {
        ...state,
        showCaptions: action.payload,
      };
    case "SET_ISINEDUCATION":
      return {
        ...state,
        isInEducation: action.payload,
      };
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    case "SET_SELECTED_CHAPTER":
      newState = {
        ...state,
        selectedChapter: action.payload.chapter,
        selectedSubchapter: action.payload.subchapter,
        selectedSubstep: action.payload.substep,
        selectedIndex: action.payload.index,
      };
      setApplicationState(newState);
      return newState;
    case "SET_SELECTED_EXERCISE_CHAPTER":
      newState = {
        ...state,
        selectedExerciseChapter: action.payload.chapter,
        selectedExerciseSubchapter: action.payload.subchapter,
        selectedExerciseSubstep: action.payload.substep,
        selectedExerciseIndex: action.payload.index,
      };
      setApplicationState(newState);
      return newState;
    case "SET_FORMDATA":
      const prop = action.payload.propertyname;
      state.formdata[prop] = action.payload[prop];
      newState = Object.assign({}, state);
      setApplicationState(newState);
      return newState;
    case "RESET_FORMDATA":
      state.formdata = {};
      state.selectedChapter = null;
      state.selectedSubchapter = null;
      state.selectedSubstep = null;
      newState = Object.assign({}, state);
      setApplicationState(newState);
      return newState;
    case "RESTORE_STATE":
      newState = {
        ...state,
        selectedChapter: action.payload.chapter,
        selectedSubchapter: action.payload.subchapter,
        selectedSubstep: action.payload.substep,
        selectedIndex: action.payload.index,
        formdata: action.payload.formdata,
      };
      return newState;
    case "CLEAR_STATE":
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  protectedcontent: [],
  outline: [],
  substeps: [],
  exercise: [],
  exercise_substeps: [],
  baseemotions: [],
  publiccontent: [],
  error: null,
  user: null,
  isLoggedIn: false,
  locale: "sv",
  selectedChapter: null,
  selectedSubchapter: null,
  selectedSubstep: null,
  selectedIndex: null,
  selectedExerciseChapter: null,
  selectedSubchapter: null,
  selectedExerciseSubstep: null,
  selectedExerciseIndex: null,
  formdata: {},
  isInEducation: false,
  showCaptions: true,
};
const StateContext = createContext({ initialState });
function useGlobalState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(`useGlobalState must be used within a Store`);
  }
  const [state, dispatch] = context;
  return {
    state,
    dispatch,
  };
}
export { useGlobalState, StateContext };

const setApplicationState = async (state) => {
  const applicationState = {
    selectedChapter: state.selectedChapter,
    selectedSubchapter: state.selectedSubchapter,
    selectedSubstep: state.selectedSubstep,
    selectedIndex: state.selectedIndex,
    formdata: state.formdata,
  };
  await AsyncStorage.setItem(
    "applicationState",
    JSON.stringify(applicationState)
  );
};
const getApplicationState = async () => {
  let applicationState = {
    selectedChapter: null,
    selectedSubchapter: null,
    selectedSubstep: null,
    selectedIndex: null,
    formdata: {},
  };
  const applicationStateString = await AsyncStorage.getItem("applicationState");
  if (applicationStateString !== null) {
    applicationState = JSON.parse(applicationStateString);
  }
  return applicationState;
};

const Store = (props) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    async function startup() {
      const applicationState = await getApplicationState();
      dispatch({ type: "RESTORE_STATE", payload: applicationState });
    }
    startup();
  }, []);

  const value = React.useMemo(() => [state, dispatch], [state]);
  return <StateContext.Provider value={value} {...props} />;
};

export const images = {
  startImage: require("../../assets/images/startimage.png"),
  logoHorizontal: require("../../assets/images/logohorizontal.png"),
};

export const trackScreenView = async (screen, user) => {
  return await trackAction(`Screen / ${screen}`, user);
};
export const trackRegistrationScreenView = async (
  screen,
  user,
  customer,
  campaign
) => {
  return await track(
    { action_name: `Screen / ${screen}`, _rcn: customer, _rck: campaign },
    user
  );
};

export const trackAppStart = async (user) => {
  return await trackAction("App / start", user);
};

export const trackAction = async (action, user) => {
  return await track({ action_name: action }, user);
};
export const trackEvent = async ({ category, action, name, value }, user) => {
  return await track(
    { e_c: category, e_a: action, e_n: name, e_v: value },
    user
  );
};
export const trackDimension = async (
  { category, action, name, dimension_name, dimension_value },
  user
) => {
  return await track({ e_c: category, e_a: action, e_n: name }, user, {
    name: dimension_name,
    value: dimension_value,
  });
};

export const track = async (data, user, dimension = undefined) => {
  if (user === undefined || user === null) {
    user = { uid: "anonymous" };
  }
  if (dimension && dimension.name && dimension.value) {
    data[dimension.name] = dimension.value;
  }

  const searchparams = new URLSearchParams({
    idsite: SITE_ID,
    rec: 1,
    apiv: 1,

    ...data,
  });
  if (user.uid !== "anonymous") {
    searchparams.uid = user.uid;
  }
  axios
    .post(TRACKER_URL, searchparams, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((data) => {})
    .catch((error) => {
      console.error(error);
    });
};

export default Store;
