import AsyncStorage from "@react-native-async-storage/async-storage";
import contentService from "../../services/content.service";
import { useGlobalState } from "../../services/store";
import { useSurveyContext } from "./surveyContext";

const SURVEY_DATA_LS_KEY = "surveyData";

export const useSurvey = () => {
  const { state } = useGlobalState();
  const {
    isSurveyOpen,
    setIsSurveyOpen,
    surveySection,
    setSurveySection,
  } = useSurveyContext();

  return {
    survey: state?.protectedcontent?.survey?.survey ?? [],
    isSurveyOpen,
    surveySectionId: surveySection,
    openSurvey: (sectionId) => {
      setSurveySection(sectionId);
      setIsSurveyOpen(true);
    },
    closeSurvey: () => setIsSurveyOpen(false),
    getSurveyAnswers,
    saveSurveyAnswers,
  };
};

const getSurveyData = async () => {
  try {
    const data = await AsyncStorage.getItem(SURVEY_DATA_LS_KEY);
    return data?.length ? JSON.parse(data) : {};
  } catch (error) {
    console.error(error);
  }
};

const getSurveyAnswers = async () => {
  try {
    const data = await getSurveyData();
    return data?.answers ?? {};
  } catch (error) {
    console.error(error);
  }
};

const getFormattedSurveyAnswers = async () => {
  const answers = await getSurveyAnswers();
  return Object.keys(answers).reduce((result, sectionKey) => {
    const sectionAnswers = answers[sectionKey];
    Object.keys(sectionAnswers).forEach((key) => {
      if (sectionAnswers[key] === true) {
        sectionAnswers[key] = "Yes";
      } else if (sectionAnswers[key] === false) {
        sectionAnswers[key] = "No";
      }
    });
    return { ...result, ...sectionAnswers };
  }, {});
};

const saveSurveyAnswers = async (sectionId, answers) => {
  try {
    const prevData = await getSurveyData();
    const prevAnswers = await getSurveyAnswers();
    await AsyncStorage.setItem(
      SURVEY_DATA_LS_KEY,
      JSON.stringify({
        ...prevData,
        answers: { ...prevAnswers, [sectionId]: answers },
      })
    );
    createOrUpdateSurveyAirtableRecord();
  } catch (error) {
    console.error(error);
  }
};

const createOrUpdateSurveyAirtableRecord = async () => {
  const surveyData = await getSurveyData();

  if (!surveyData.answers) {
    return;
  }

  if (!surveyData.rid) {
    await createSurveyAirtableRecord(surveyData);
  } else {
    await updateSurveyAirtableRecord(surveyData);
  }
};

const createSurveyAirtableRecord = async (surveyData) => {
  try {
    const result = await contentService.postSurveyAnswers(
      getFormattedSurveyAnswers()
    );
    await AsyncStorage.setItem(
      SURVEY_DATA_LS_KEY,
      JSON.stringify({
        ...surveyData,
        postedAt: new Date(),
        rid: result.data.rid,
      })
    );
  } catch (error) {
    console.error(error);
  }
};

const updateSurveyAirtableRecord = async (surveyData) => {
  try {
    await contentService.postSurveyAnswers(
      await getFormattedSurveyAnswers(),
      surveyData.rid
    );
    await AsyncStorage.setItem(
      SURVEY_DATA_LS_KEY,
      JSON.stringify({
        ...surveyData,
        updatedAt: new Date(),
      })
    );
  } catch (error) {
    console.error(error);
  }
};
