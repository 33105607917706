import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useGlobalState } from "../services/store";
import { Theme } from "../styles";

const StepProgress = (props) => {
  const [chapterIndex, setChapterIndex] = useState(0);
  const { state } = useGlobalState();

  const getSelectedChapter = () => {
    return props.isExercise
      ? state.selectedExerciseChapter
      : state.selectedChapter;
  };
  const getSelectedSubchapter = () => {
    return props.isExercise
      ? state.selectedExerciseSubchapter
      : state.selectedSubchapter;
  };
  const getSelectedSubstep = () => {
    return props.isExercise
      ? state.selectedExerciseSubstep
      : state.selectedSubstep;
  };
  const [currentSubchapters, setCurrentSubchapters] = useState([]);
  useEffect(() => {}, [currentSubchapters]);

  useEffect(() => {
    setCurrentSubchapters(
      props.isExercise
        ? state.selectedExerciseChapter?.subchapters
        : state.selectedChapter?.subchapters
    );

    if (
      getSelectedChapter() &&
      getSelectedSubchapter() &&
      getSelectedChapter().subchapters
    ) {
      const chindex = getSelectedChapter().subchapters.findIndex(
        (subchapter, index) => {
          return subchapter.id === getSelectedSubchapter().id;
        }
      );
      setChapterIndex(chindex);
    } else {
      setChapterIndex(0);
    }
  }, [
    props.isExercise,
    state.selectedChapter,
    state.selectedSubchapter,
    state.selectedSubstep,
    state.selectedExerciseChapter,
    state.selectedExerciseSubchapter,
    state.selectedExerciseSubstep,
  ]);

  const stepstyle = {
    borderRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Theme.textColorFaded,
  };
  const subStepstyle = {
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: Theme.textColorFaded,
  };
  const selectedEndStepstyle = {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 5,
    backgroundColor: Theme.textColorFaded,
  };
  const selectedStartStepstyle = {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 0,
    backgroundColor: Theme.textColorFaded,
  };
  const renderStep = (subchapter, index) => {
    const opacitystyle = {};
    if (index === chapterIndex) {
      opacitystyle.backgroundColor = Theme.secondary;
      const selectedSubstepIndex = subchapter.substeps.findIndex(
        (substep, index) => {
          return substep.id === getSelectedSubstep()?.id;
        }
      );
      if (subchapter.substeps && subchapter.substeps.length > 1) {
        return (
          <View
            key={`progressstep_${subchapter.id}`}
            style={styles.showSubsteps}
          >
            {subchapter.substeps.map((substep, innerindex) => {
              const substepSelectedStyle = {};
              if (innerindex <= selectedSubstepIndex) {
                substepSelectedStyle.backgroundColor = Theme.secondary;
              }
              if (innerindex === 0) {
                return (
                  <View
                    key={`substep_${substep.id}`}
                    style={{
                      ...selectedStartStepstyle,
                      ...substepSelectedStyle,
                    }}
                  />
                );
              } else if (innerindex === subchapter.substeps.length - 1) {
                return (
                  <View
                    key={`substep_${substep.id}`}
                    style={{ ...selectedEndStepstyle, ...substepSelectedStyle }}
                  />
                );
              } else {
                return (
                  <View
                    key={`substep_${substep.id}`}
                    style={{ ...subStepstyle, ...substepSelectedStyle }}
                  />
                );
              }
            })}
          </View>
        );
      } else {
        return (
          <View
            key={`progressstep_${subchapter.id}`}
            style={{ ...stepstyle, ...opacitystyle }}
          />
        );
      }
    } else {
      return <View key={`progressstep_${subchapter.id}`} style={stepstyle} />;
    }
  };
  return (
    <View style={styles.container}>
      {currentSubchapters &&
        currentSubchapters.map((subchapter, index) => {
          return renderStep(subchapter, index);
        })}
    </View>
  );
};
var styles = StyleSheet.create({
  container: {
    flex: -1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  showSubsteps: {
    flex: -1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default StepProgress;
