import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigationState } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Appbar, Menu, Modal, Portal } from "react-native-paper";
import AuthService from "../services/auth.service";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Layout, Theme } from "../styles";
import { SecondaryButton } from "./Buttons";
import Login from "./Login";

const CustomHeader = ({ navigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [maxWidth, setMaxWidth] = useState(Dimensions.get("window").width);
  const [maxHeight, setMaxHeight] = useState(Dimensions.get("window").height);
  const insets = useSafeAreaInsets();
  const hideModal = () => setModalVisible(false);

  const containerStyle = {
    backgroundColor: "white",
    padding: 20,
    minHeight: 400,
  };
  const { state, dispatch } = useGlobalState();
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const logout = () => {
    AuthService.logout().then(
      () => {
        dispatch({ type: "CLEAR_STATE", payload: null });
      },
      (error) => {
        dispatch({ type: "CLEAR_STATE", payload: null });
      }
    );
  };
  const changeLanguage = (locale, persist = true) => {
    dispatch({ type: "SET_LOCALE", payload: locale });
    if (persist) {
      AsyncStorage.setItem("lang", locale);
    }
  };
  useEffect(() => {
    AsyncStorage.getItem("lang").then((lang) => {
      if (lang != null) {
        changeLanguage(lang, false);
      } else {
        changeLanguage("sv", true);
      }
    });
    const updateLayout = () => {
      setMaxWidth(Dimensions.get("window").width);
      setMaxHeight(Dimensions.get("window").height);
    };
    const listener = Dimensions.addEventListener("change", updateLayout);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    i18n.locale = state.locale;
  }, [state.locale]);

  useEffect(() => {}, [state.isLoggedIn]);

  const screenName = useNavigationState(
    (state) => state.routes[state.index].name
  );

  return (
    <>
      {true && (
        <Appbar.Header
          style={{
            backgroundColor: Theme.pageBg,
            width: "100%",
            borderBottomWidth: 0,
            shadowColor: "transparent",
            elevation: 0,
            position: "absolute",
            top: insets.top,
          }}
        >
          {state.isLoggedIn ? (
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              anchor={
                <Appbar.Action
                  icon="menu"
                  color={Theme.secondary}
                  onPress={openMenu}
                />
              }
            >
              <Menu.Item
                onPress={() => {
                  navigation.navigate("Education");
                }}
                title={i18n.t("navigation.education")}
              />
              <Menu.Item
                onPress={() => {
                  navigation.navigate("Exercise", { step: 0 });
                }}
                title={i18n.t("navigation.exercise")}
              />
              <Menu.Item onPress={logout} title={i18n.t("buttons.logout")} />
            </Menu>
          ) : (
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              anchor={
                <Appbar.Action
                  icon="menu"
                  onPress={openMenu}
                  color={Theme.secondary}
                />
              }
            >
              <Menu.Item
                onPress={() => {
                  navigation.navigate("Education");
                }}
                title={i18n.t("navigation.education")}
              />
              <Menu.Item
                onPress={() => {
                  navigation.navigate("Register");
                }}
                title={i18n.t("navigation.register")}
              />
            </Menu>
          )}
          <Portal>
            <Modal
              visible={modalVisible}
              onDismiss={hideModal}
              contentContainerStyle={containerStyle}
            >
              <Login />
            </Modal>
          </Portal>
          <Appbar.Content
            style={{ opacity: 0 }}
            title={i18n.t("appTitle", {
              appName: i18n.t("appName"),
              view: i18n.t(`navigation.${screenName.toLowerCase()}`),
            })}
          />
          {state.isLoggedIn && maxWidth < Layout.medium && (
            <SecondaryButton
              onPress={() => {
                navigation.navigate("Exercise", { step: 0 });
              }}
              text={i18n.t("buttons.directtoexercise")}
              style={{ right: 15 }}
              color={Theme.primary}
            />
          )}
        </Appbar.Header>
      )}
    </>
  );
};

export default CustomHeader;
