import {
  CommonActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import BottomChapterNav from "../components/BottomChapterNav";
import ContentStep from "../components/ContentStep";
import EducationOutline from "../components/Outline/EducationOutline";
import EmotionStep from "../components/EmotionStep";
import RadioFormStep from "../components/RadioFormStep";
import RangeStep from "../components/RangeStep";
import ResultStep from "../components/Result/ResultStep";
import Survey from "../components/Survey/Survey";
import { useSurvey } from "../components/Survey/useSurvey";
import Swiper from "../components/Swiper";
import TextFormStep from "../components/TextFormStep";
import VideoStep from "../components/VideoStep";
import ChapterNavigation from "../services/chapternavigation.service";
import { trackScreenView, useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Layout, Texts, Theme } from "../styles";

const EducationStep = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { state, dispatch } = useGlobalState();
  const { openSurvey, getSurveyAnswers } = useSurvey();
  const { findChaptersForIndex } = ChapterNavigation();
  const [stepDone, setStepDone] = useState(false);
  const stepParam = useRef(undefined);
  const [currentSubsteps, setCurrentSubsteps] = useState([]);
  const [deviceWidth, setDeviceWidth] = useState(
    Dimensions.get("window").width
  );
  const [deviceHeight, setDeviceHeight] = useState(
    Dimensions.get("window").height
  );
  const insets = useSafeAreaInsets();
  const isExercise = useRef(false);
  const getSelectedIndex = () => {
    return isExercise.current
      ? state.selectedExerciseIndex
      : state.selectedIndex;
  };
  const getSelectedSubchapter = () => {
    return isExercise.current
      ? state.selectedExerciseSubchapter
      : state.selectedSubchapter;
  };
  const videoRef = useRef(null);
  const getTrackingType = (index) => {
    let result = "educationstep";
    if (isExercise.current === true) {
      result = "exercisestep";
    }
    if (currentSubsteps.length > index) {
      const step = currentSubsteps[index];
      if (step.substep && step.substep.type === "result") {
        result = "resultview";
      }
    }
    return result;
  };
  const getIsExercise = () => {
    let _isExercise = false;
    if (route) {
      const params = route.params;
      if (route.name === "Chapter") {
        if (params !== undefined) {
          if (params.step !== undefined) {
            stepParam.current = +params.step;
          }
        }
      }
      if (route.name === "Exercise") {
        _isExercise = true;
        if (params !== undefined) {
          if (params.step !== undefined) {
            stepParam.current = +params.step;
          }
        } else {
          stepParam.current = 0;
        }
      }
    }
    return _isExercise;
  };
  useEffect(() => {
    if (!state.isLoggedIn) {
      navigation.navigate("Main", { screen: "Education" });
    }
    if (!state.isInEducation) {
      dispatch({ type: "SET_ISINEDUCATION", payload: true });
    }

    const updateLayout = () => {
      setDeviceWidth(Dimensions.get("window").width);
      setDeviceHeight(Dimensions.get("window").height);
    };
    const listener = Dimensions.addEventListener("change", updateLayout);
    return () => {
      listener.remove();
    };
  }, []);
  useEffect(() => {
    isExercise.current = getIsExercise();
    if (
      state.substeps &&
      state.substeps.length &&
      state.exercise_substeps &&
      state.exercise_substeps.length
    ) {
      setCurrentSubsteps(
        isExercise.current ? state.exercise_substeps : state.substeps
      );
    }
  }, [state.substeps, state.exercise_substeps]);

  useEffect(() => {
    setStepDone(false);
    if (!state.isLoggedIn) {
      navigation.navigate("Main", { screen: "Education" });
    }
    if (!state.isInEducation) {
      dispatch({ type: "SET_ISINEDUCATION", payload: true });
    }
    isExercise.current = getIsExercise();
  }, [route.params?.step]);

  const getPercentageHeight = (percentage) => {
    return Math.floor((percentage / 100) * deviceHeight);
  };

  const isDark = true;

  useEffect(() => {
    if (stepParam.current !== undefined && currentSubsteps.length > 0) {
      swiperRef.current.refresh();
      goto(stepParam.current, true);
    }
  }, [currentSubsteps]);

  const checkSurvey = async (subchapter) => {
    const surveySectionId = subchapter?.survey_section_id;
    if (surveySectionId?.length) {
      const answers = await getSurveyAnswers();
      if (answers[surveySectionId]) {
        return false;
      }

      openSurvey(surveySectionId);
      return true;
    }

    return false;
  };

  const gotoNext = async (subchapter) => {
    const selectedSubchapter = subchapter ?? getSelectedSubchapter();
    const doSurvey = await checkSurvey(selectedSubchapter);
    if (doSurvey) {
      videoRef.current?.endVideo();
      return;
    }
    if (
      videoRef.current != null &&
      state.selectedSubstep?.done_content?.length &&
      videoRef.current.doneContentVisible === false
    ) {
      videoRef.current?.endVideo();
    } else {
      if (getSelectedIndex() === currentSubsteps.length - 1) {
        close();
      } else {
        const index = swiperRef.current.getActiveIndex() + 1;
        goto(index, true);
      }
    }
  };
  const goto = (index, updatestate = false) => {
    swiperRef.current.goTo(index);
  };
  const gotoPrevious = () => {
    const index = swiperRef.current.getActiveIndex() - 1;
    goto(index, true);
  };
  const updateByIndex = (index) => {
    const payload = findChaptersForIndex(index, isExercise.current);
    if (payload) {
      if (isExercise.current) {
        dispatch({ type: "SET_SELECTED_EXERCISE_CHAPTER", payload: payload });
      } else {
        dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
      }
    }
    let step = +route.params?.step;
    navigation.navigate(isExercise.current ? "Exercise" : "Chapter", {
      step: index,
    });
    const trackingType = getTrackingType(index);
    trackScreenView(`${trackingType} / ${index}`, state.user);
  };
  const resultDialogRef = useRef(null);

  const openResultModal = (stepindex) => {
    return (viewid, views) => {
      resultDialogRef.current.open(viewid, views, stepindex);
    };
  };

  const swiperRef = useRef(null);
  const stepDoneChanged = (isDone) => {
    setStepDone(isDone);
  };
  const close = (reset = false) => {
    dispatch({ type: "SET_ISINEDUCATION", payload: false });
    if (reset) {
      dispatch({ type: "RESET_FORMDATA", payload: false });
    }
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: "Main",
            state: {
              routes: [
                {
                  name: "Education",
                },
              ],
            },
          },
        ],
      })
    );
  };
  const startNew = () => {
    dispatch({ type: "RESET_FORMDATA", payload: false });
    if (isExercise.current) {
      goto(0, true);
    } else {
      navigation.navigate("Exercise", { step: 0 });
    }
  };
  const getResponsiveStyle = (breakpoints) => {
    if (breakpoints.mobile && deviceWidth < Layout.medium) {
      return breakpoints.mobile;
    }
    if (breakpoints.tablet && deviceWidth >= Layout.medium) {
      return breakpoints.tablet;
    }
  };

  const SelectStepType = (
    chapter,
    subchapter,
    substep,
    index,
    paddingBottom
  ) => {
    let theLayout = "mobile";
    if (deviceWidth >= Layout.medium) {
      theLayout = "tablet";
    }
    let paddingTop = 50 + insets.top;
    switch (substep.type) {
      case "video":
        return (
          <VideoStep
            key={`step_${index}`}
            ref={videoRef}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            playStateChanged={stepDoneChanged}
            layout={theLayout}
            getResponsiveStyle={getResponsiveStyle}
            deviceWidth={deviceWidth}
            deviceHeight={deviceHeight}
            gotoNext={() => gotoNext(subchapter)}
            onSkip={async () => await checkSurvey(subchapter)}
            onShowDoneContent={async () => {
              const showSurvey = await checkSurvey(subchapter);
              if (!showSurvey) {
                videoRef.current?.showDoneContent();
              }
            }}
            onHideDoneContent={() => videoRef.current?.hideDoneContent()}
          />
        );

      case "emotion":
        return (
          <EmotionStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            emotionSelectedChanged={stepDoneChanged}
            layout={theLayout}
            getResponsiveStyle={getResponsiveStyle}
            gotoNext={() => gotoNext(subchapter)}
          />
        );

      case "freetext":
        return (
          <TextFormStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            textChanged={stepDoneChanged}
            layout={theLayout}
            gotoNext={() => gotoNext(subchapter)}
            getResponsiveStyle={getResponsiveStyle}
          />
        );

      case "radio":
        return (
          <RadioFormStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            selectionChanged={stepDoneChanged}
            layout={theLayout}
            gotoNext={() => gotoNext(subchapter)}
            getResponsiveStyle={getResponsiveStyle}
          />
        );
      case "range":
        return (
          <RangeStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            rangeChanged={stepDoneChanged}
            layout={theLayout}
            gotoNext={() => gotoNext(subchapter)}
            getResponsiveStyle={getResponsiveStyle}
          />
        );
      case "result":
        return (
          <ResultStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            gotoNext={() => gotoNext(subchapter)}
            close={close}
            startNew={startNew}
            layout={theLayout}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            openResultModal={openResultModal(index)}
            getResponsiveStyle={getResponsiveStyle}
          />
        );
      case "content":
        return (
          <ContentStep
            key={`step_${index}`}
            chapter={chapter}
            subchapter={subchapter}
            substep={substep}
            stepindex={index}
            gotoNext={() => gotoNext(subchapter)}
            goto={(index) => goto(index)}
            layout={theLayout}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            deviceWidth={deviceWidth}
            getResponsiveStyle={getResponsiveStyle}
          />
        );
      default:
        return <Text key={`step_${index}`}>no data</Text>;
    }
  };

  return (
    <>
      <View
        style={{
          flex: 1,
          backgroundColor: Theme.pageBg,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",
              padding: 20,
              paddingTop: 15 + insets.top,
            }}
          >
            <View>
              <TouchableOpacity
                style={{
                  display:
                    deviceWidth >= Layout.medium || getSelectedIndex() == 0
                      ? "none"
                      : "flex",
                }}
                onPress={gotoPrevious}
              >
                <Icon
                  color={Theme.textColor}
                  name="keyboard-arrow-left"
                  size={28}
                  type="material"
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  display: deviceWidth >= Layout.medium ? "flex" : "none",
                }}
                onPress={() => {
                  close(false);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={Theme.textColor}
                    name="close"
                    size={28}
                    type="material"
                  />
                  <Text
                    style={{
                      ...Texts.closeLink,
                      paddingTop: 5,
                      paddingLeft: 5,
                    }}
                  >
                    {i18n.t("buttons.close")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                ...Texts.h4,
                marginTop: 8,
                display: deviceWidth >= Layout.medium ? "none" : "flex",
                flexShrink: 2,
              }}
            >
              {getSelectedSubchapter() && getSelectedSubchapter()?.chapter}
            </Text>
            <TouchableOpacity
              style={{
                marginTop: 1,
                display: deviceWidth >= Layout.medium ? "none" : "flex",
              }}
              onPress={() => {
                close(false);
              }}
            >
              <Icon
                color={Theme.textColor}
                name="keyboard-arrow-down"
                size={28}
                type="material"
              />
            </TouchableOpacity>
          </View>
        </View>

        <View
          style={{
            flex: 1,
            position: "relative",
            justifyContent: "space-between",
            alignItems: "stretch",
            ...getResponsiveStyle({
              mobile: {
                flexDirection: "column",
              },
              tablet: {
                flexDirection: "row",
              },
            }),
          }}
        >
          <Swiper
            isDark={isDark}
            animate={deviceWidth < Layout.medium}
            containerStyle={{
              ...getResponsiveStyle({
                mobile: {},
                tablet: {
                  minWidth: Layout.minContent,
                  flex: 1,

                  height: getPercentageHeight(95),
                  top: getPercentageHeight(5),
                },
              }),
            }}
            isDone={stepDone}
            controlsBottomPos={getPercentageHeight(5)}
            isFinalStep={getSelectedIndex() === currentSubsteps.length - 1}
            firstSelected={getSelectedIndex() === 0}
            gesturesEnabled={() => false}
            controlsEnabled={
              deviceWidth >= Layout.medium &&
              getSelectedIndex() < currentSubsteps.length - 1
            }
            ref={swiperRef}
            onIndexChanged={updateByIndex}
            gotoNextExternal={() => gotoNext()}
          >
            {currentSubsteps.map((step, index) => {
              return SelectStepType(
                step.chapter,
                step.subchapter,
                step.substep,
                index,
                index < currentSubsteps.length - 1 ? getPercentageHeight(15) : 0
              );
            })}
          </Swiper>
          {deviceWidth >= Layout.medium && (
            <EducationOutline
              isDark={isDark}
              isExercise={isExercise.current}
              goto={goto}
            />
          )}
        </View>

        {deviceWidth < Layout.medium &&
          getSelectedIndex() < currentSubsteps.length - 1 && (
            <BottomChapterNav
              isDone={stepDone}
              isExercise={isExercise.current}
              gotoNext={() => gotoNext()}
              isDark={isDark}
              isFinalStep={getSelectedIndex() === currentSubsteps.length - 1}
            />
          )}
      </View>
      <Survey
        getResponsiveStyle={getResponsiveStyle}
        onContinue={() => {
          if (getSelectedIndex() === currentSubsteps.length - 1) {
            close();
          } else {
            const index = swiperRef.current.getActiveIndex() + 1;
            goto(index, true);
          }
        }}
        onPlayAgain={() => videoRef.current?.replay()}
      />
    </>
  );
};

export default EducationStep;
