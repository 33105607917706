import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import BeginBar from "../components/BeginBar";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import CenteredContent from "../components/CenteredContent";
import ImageComponent from "../components/Image";
import Login from "../components/Login";
import Outline from "../components/Outline/Outline";
import Renderer from "../helpers/renderer";
import ChapterNavigation from "../services/chapternavigation.service";
import { images, trackScreenView, useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Layout, Theme } from "../styles";

const Education = () => {
  const { findIndexForSubChapter, findChaptersForIndex } = ChapterNavigation();
  const [loginContent, setLoginContent] = useState([]);
  const navigation = useNavigation();
  const { state, dispatch } = useGlobalState();
  const [viewContent, setViewContent] = useState([]);
  const [maxWidth, setMaxWidth] = useState(Dimensions.get("window").width);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    const updateLayout = () => {
      setMaxWidth(Dimensions.get("window").width);
    };
    const listener = Dimensions.addEventListener("change", updateLayout);
    trackScreenView("educationlandingpage", state.user);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const loginData =
      state?.publiccontent &&
      state.publiccontent.filter((view) => view.step_id == "login");
    if (loginData && loginData.length > 0) {
      setLoginContent(loginData[0].view_content);
    }
  }, [state.publiccontent, state.locale]);

  useEffect(() => {
    const viewData =
      state.protectedcontent?.views &&
      state.protectedcontent.views.filter(
        (view) => view.step_id == "educationcontent"
      );
    if (viewData && viewData.length > 0) {
      setViewContent(viewData[0]);
    }
  }, [state.protectedcontent, state.locale]);

  useEffect(() => {
    if (
      state.selectedSubchapter === null ||
      state.selectedSubchapter === undefined
    ) {
      const payload = findChaptersForIndex(0);
      if (payload) {
        dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
      }
    }
  }, [state.substeps]);

  const openChapter = (chapterid) => {
    const index = findIndexForSubChapter(chapterid);
    navigation.navigate("Chapter", { step: index });
  };

  const openCurrentChapter = () => {
    if (state.selectedIndex === undefined || state.selectedIndex === null) {
      navigation.navigate("Chapter", { step: 0 });
    } else {
      navigation.navigate("Chapter", { step: state.selectedIndex });
    }
  };

  const getResponsiveStyle = (breakpoints) => {
    if (breakpoints.mobile && maxWidth < Layout.medium) {
      return breakpoints.mobile;
    }
    if (breakpoints.tablet && maxWidth >= Layout.medium) {
      return breakpoints.tablet;
    }
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Theme.pageBg,
      }}
    >
      <View
        style={{
          flex: 1,
          position: "absolute",
          left: 0,
          right: 0,
          ...getResponsiveStyle({
            mobile: { top: 50 + insets.top, bottom: 80 + insets.bottom },
            tablet: { top: 0, bottom: 0 },
          }),
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          {maxWidth >= Layout.medium && (
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 120,
                right: 0,
                width: "60%",
              }}
            >
              <ImageComponent
                style={{
                  resizeMode: "contain",
                }}
                maxWidth={640}
                image={images.startImage}
              />
            </View>
          )}
          <View
            style={{
              flex: 1,
              ...getResponsiveStyle({
                mobile: {
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginTop: 5,
                },
                tablet: {
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              }),
            }}
          >
            {!state.isLoggedIn && (
              <CenteredContent
                minWidth={Layout.minContent}
                maxWidth={600}
                active={maxWidth >= Layout.medium}
              >
                <View
                  style={{
                    flex: 1,
                    margin: 20,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "stretch",
                    maxWidth: Layout.maxDesktop,
                  }}
                >
                  {loginContent &&
                    loginContent.map((component, index) =>
                      Renderer(component, index)
                    )}
                  <Login isRegisterPage={false} />
                </View>
              </CenteredContent>
            )}
            {state.isLoggedIn && (
              <>
                <CenteredContent
                  minWidth={Layout.minContent}
                  maxWidth={Layout.maxContentSmall}
                  alignTop={true}
                  active={maxWidth >= Layout.medium}
                >
                  <View
                    style={{
                      padding: 20,
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        position: "relative",
                        zIndex: 20,
                      }}
                    >
                      <View style={{ flex: 1, justifyContent: "flex-start" }}>
                        <View
                          style={{
                            ...getResponsiveStyle({
                              mobile: { marginTop: 20 },
                              tablet: {
                                marginTop: 120,
                              },
                            }),
                          }}
                        ></View>
                        {viewContent &&
                          viewContent.view_content &&
                          viewContent.view_content.map((component, index) => {
                            return Renderer(component, index);
                          })}
                        {maxWidth >= Layout.medium && (
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <PrimaryButton
                              onPress={openCurrentChapter}
                              style={{
                                marginRight: 10,
                              }}
                              text={i18n.t("buttons.begin")}
                              disabled={state.outline?.chapters?.length === 0}
                            />

                            <SecondaryButton
                              onPress={() => {
                                navigation.navigate("Exercise", { step: 0 });
                              }}
                              style={{
                                marginLeft: 10,
                              }}
                              text={i18n.t("buttons.directtoexercise")}
                            />
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </CenteredContent>
                <View
                  style={getResponsiveStyle({
                    mobile: {},
                    tablet: { width: 580 },
                  })}
                >
                  {maxWidth < Layout.medium && (
                    <>
                      <View
                        style={{
                          paddingRight: 20,
                          paddingLeft: 20,
                          marginBottom: 60,
                        }}
                      >
                        <ImageComponent
                          style={{
                            resizeMode: "contain",
                          }}
                          position="left"
                          maxWidth={320}
                          image={images.logoHorizontal}
                        />
                      </View>

                      <View
                        style={{
                          padding: 20,
                          paddingRight: 0,
                          backgroundColor: Theme.outlineBg,
                        }}
                      >
                        <Outline
                          outline={state.outline}
                          openChapter={openChapter}
                          selectedSubchapter={state.selectedSubchapter}
                        />
                      </View>
                    </>
                  )}
                </View>
              </>
            )}
          </View>
        </ScrollView>
      </View>

      {state.isLoggedIn && maxWidth < Layout.medium && (
        <BeginBar layout="mobile" openChapter={openCurrentChapter} />
      )}
    </View>
  );
};
export default Education;
