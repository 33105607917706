import React, { useEffect, useRef, useState } from "react";
import { Dimensions, Image, StyleSheet, View } from "react-native";
import { Theme, Layout } from "../styles";

const ImageComponent = ({
  image,
  position,
  style,
  limitHeight,
  maxWidth,
  layoutFunc,
  color,
  borderRadius,
  padding,
}) => {
  if (typeof image === "number") image = Image.resolveAssetSource(image);
  const { windowWidth } = Dimensions.get("window");
  const [parentWidth, setParentWidth] = useState(
    windowWidth ? windowWidth : Layout.maxDesktop
  );
  const [transform, setTransform] = useState([{ translateY: 0 }]);
  const isMounted = useRef(false);

  const handleSize = (event) => {
    if (isMounted.current) {
      const { width } = event.nativeEvent.layout;
      setParentWidth(width);
      if (layoutFunc) {
        layoutFunc(event.nativeEvent.layout);
      }
    }
  };

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const propstyles = {};
  const plateStyles = {};

  if (position) {
    switch (position) {
      case "center":
        propstyles.alignItems = "center";
        break;
      case "left":
        propstyles.alignItems = "flex-start";
        break;
      case "right":
        propstyles.alignItems = "flex-end";
        break;
      default:
        break;
    }
  }
  if (color && color !== "transparent") {
    plateStyles.backgroundColor = Theme.primary;
    plateStyles.padding = +padding;
    plateStyles.borderRadius = +borderRadius;
  }

  const getAbsoluteYTranslation = (percentage, theHeight) => {
    return Math.floor(theHeight * (percentage / 100));
  };

  let alignStyle = {};
  if (style && style.alignSelf) {
    alignStyle.flex = 1;
    alignStyle.justifyContent = style.alignSelf;
  }
  function adjustSize(w, h) {
    let resultingHeight = 0;
    const imWidth = maxWidth ? +maxWidth : w;
    const compareWidth = imWidth < parentWidth ? imWidth : parentWidth;
    if (isMounted.current) {
      const ratio = compareWidth / imWidth;
      const fixedRatio = h / w;
      if (imWidth > compareWidth) {
        setWidth(compareWidth);

        if (limitHeight && h * ratio > h) {
          setHeight(h);
          resultingHeight = h;
        } else {
          setHeight(h * ratio);
          resultingHeight = h * ratio;
        }
      } else {
        setWidth(imWidth);
        setHeight(imWidth * fixedRatio);
        resultingHeight = imWidth * fixedRatio;
      }

      if (style && style.translateY) {
        const translateY = getAbsoluteYTranslation(
          style.translateY,
          resultingHeight
        );
        setTransform([{ translateY: translateY }]);
      }
    }
  }
  useEffect(() => {
    isMounted.current = true;
    if (image?.width) {
      adjustSize(image.width, image.height);
    } else {
      Image.getSize(image, adjustSize);
    }

    return () => {
      isMounted.current = false;
    };
  }, [parentWidth, maxWidth]);

  return (
    <View style={{ ...alignStyle, transform: transform }}>
      <View
        style={StyleSheet.flatten([
          styles.imageContainer,
          propstyles,
          plateStyles,
        ])}
        onLayout={handleSize}
      >
        <Image
          style={{
            resizeMode: "contain",
            width: width,
            height: height,
          }}
          source={{ uri: image?.uri || image }}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  imageContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ImageComponent;
