import React from "react";
import { ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CenteredContent from "../../components/CenteredContent";
import Renderer, { isComponent } from "../../helpers/renderer";
import i18n from "../../services/translations";
import { Layout } from "../../styles";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Spacer } from "../Spacer";
import ResultView from "./ResultView";

const ResultStep = (props) => {
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        ...props.getResponsiveStyle({
          mobile: {
            paddingTop: props.paddingTop,
            paddingBottom: insets.bottom,
          },
          tablet: { paddingBottom: props.paddingBottom },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            ...props.getResponsiveStyle({
              mobile: { padding: 20 },
              tablet: {
                paddingLeft: 200,
                paddingRight: 80,
                paddingTop: 60,
                paddingBottom: 60,
              },
            }),
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: -1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <CenteredContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
              >
                {props.substep &&
                  props.substep.view_content &&
                  props.substep.view_content.map((component, index) => {
                    if (isComponent(component, "paragraph")) {
                      return Renderer(
                        component,
                        index,
                        { fontSize: 20, lineHeight: 24 },
                        props.openResultModal
                      );
                    } else if (isComponent(component, "result_view")) {
                      return (
                        <ResultView
                          key={"view_" + index}
                          getResponsiveStyle={props.getResponsiveStyle}
                        />
                      );
                    } else {
                      return Renderer(
                        component,
                        index,
                        {},
                        props.openResultModal
                      );
                    }
                  })}
                <Spacer size={30} />
                <View style={{ alignItems: "center" }}>
                  <View
                    style={{
                      alignItems: "stretch",
                      justifyContent: "flex-end",
                      width: 320,
                    }}
                  >
                    <SecondaryButton
                      onPress={() => {
                        props.startNew();
                      }}
                      text={i18n.t("buttons.exploreagain")}
                      style={{
                        marginBottom: 10,
                      }}
                    />
                    <PrimaryButton
                      onPress={() => {
                        props.close(true, true);
                      }}
                      text={i18n.t("buttons.finish")}
                    />
                  </View>
                </View>
              </CenteredContent>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default ResultStep;
