import React from "react";
import { View } from "react-native";
import i18n from "../services/translations";
import { PrimaryButton } from "./Buttons";

export const StepButton = ({ customProps, data }) => {
  const gotoNext = customProps?.gotoNext ? customProps.gotoNext : () => {};

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <PrimaryButton
        onPress={gotoNext}
        text={data.text ?? i18n.t("buttons.continue")}
      />
    </View>
  );
};

export default StepButton;
