import React from "react";
import { ScrollView, TextInput, View } from "react-native";
import Accordion from "../components/Accordion";
import IconHeader from "../components/IconHeader";
import JustifyContent from "../components/JustifyContent";
import Renderer from "../helpers/renderer";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Inputs, Layout } from "../styles";

const TextFormStep = (props) => {
  const getResponsiveStyle = props.getResponsiveStyle;
  const { state, dispatch } = useGlobalState();

  const onChangeText = (value, field) => {
    const payload = { propertyname: field };
    payload[field] = value;
    dispatch({ type: "SET_FORMDATA", payload: payload });

    if (props.textChanged) {
      props.textChanged(value?.length > 0);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        ...getResponsiveStyle({
          mobile: { paddingTop: props.paddingTop, paddingBottom: 80 },
          tablet: { paddingBottom: props.paddingBottom },
        }),
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "center",
            padding: 20,
            alignItems: "stretch",
            ...getResponsiveStyle({
              tablet: {
                paddingTop: 70,
                paddingRight: 70,
                paddingBottom: 20,
                paddingLeft: 70,
              },
            }),
          }}
        >
          <View
            style={{
              flex: 1,
              maxWidth: Layout.maxContent,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: -1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <IconHeader
                color={props.subchapter.color}
                image={props.subchapter.chapter_image}
                title={props.substep.title}
                chapter_keyword={props.subchapter.chapter_keyword}
              />
              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
                position="left"
              >
                {props.substep &&
                  props.substep.view_content &&
                  props.substep.view_content.map((component, index) =>
                    Renderer(component, index)
                  )}
                {props.substep.help_content.length > 0 && (
                  <Accordion open={false} text={i18n.t("buttons.showmore")}>
                    {props.substep.help_content.map((component, index) =>
                      Renderer(component, index)
                    )}
                  </Accordion>
                )}
              </JustifyContent>
            </View>
            <View></View>
            <View>
              <JustifyContent
                minWidth={Layout.minContent}
                maxWidth={Layout.maxDesktop}
                position="left"
              >
                {props.substep &&
                  props.substep.textplaceholders &&
                  props.substep.textplaceholders.map(
                    (textplaceholder, index) => (
                      <TextInput
                        key={`textinput_${index}`}
                        placeholder={textplaceholder.label}
                        multiline={true}
                        style={{
                          ...Inputs.textArea,
                          height: 100,
                          textAlignVertical: "top",
                        }}
                        onChangeText={(e) => {
                          onChangeText(e, textplaceholder.data_field);
                        }}
                        value={state.formdata[textplaceholder.data_field]}
                        labelStyle={Inputs.label}
                      />
                    )
                  )}
              </JustifyContent>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
export default TextFormStep;
