import { Dimensions } from "react-native";
import { Layout } from "../styles";
import i18n from "../services/translations";
import { useCallback, useState, useEffect } from "react";

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const exists = (val) => val !== null && val !== undefined;

export const capitalizeString = (string) =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export const getTranslation = (text, state) => {
  i18n.locale = state.locale;
  let regex = /{(variant\d{1,2})}/gim;
  let match;
  let newText = text;
  let emotionVariant = "";

  if (exists(state.formdata.customemotion)) {
    emotionVariant = "custom";
  } else if (exists(state.formdata.emotion)) {
    emotionVariant = state.formdata.emotion;
  } else if (exists(state.formdata.baseemotion)) {
    emotionVariant = state.formdata.baseemotion;
  } else {
    emotionVariant = "unchosen";
  }

  while ((match = regex.exec(text)) !== null) {
    let translation = i18n.t(`emotions.${match[1]}.${emotionVariant}`);
    if (translation !== undefined) {
      newText = newText.replace(`{${match[1]}}`, translation);
    }
  }
  return newText;
};

export const getBoldWords = (text) => {
  let regex = /({b:[^}]+})/gim;
  let innerregex = /{b:([^}]+)}/gim;
  let parts = [];

  let splittedText = text.split(regex);
  splittedText.forEach((word) => {
    let hadMatch = false;
    let match;
    while ((match = innerregex.exec(word)) !== null) {
      hadMatch = true;
      parts.push({ bold: true, text: match[1] });
    }
    if (!hadMatch) {
      parts.push({ bold: false, text: word });
    }
  });
  return parts;
};
export const getBaseEmotion = (emotionid, state) => {
  const emotions = state.baseemotions.filter((emotion) => {
    return emotion.id === emotionid;
  });
  if (emotions && emotions.length > 0) {
    return emotions[0];
  } else {
    return undefined;
  }
};
export const getBaseEmotionTitle = (emotionid, state) => {
  const baseEmotion = getBaseEmotion(emotionid, state);
  if (baseEmotion) {
    return baseEmotion.title;
  } else {
    return undefined;
  }
};
export const getEmotion = (emotionid, baseemotion) => {
  const emotions = baseemotion.emotions.filter((emotion) => {
    return emotion.id === emotionid;
  });
  if (emotions && emotions.length > 0) {
    return emotions[0];
  } else {
    return undefined;
  }
};

export const getEmotionTitle = (state) => {
  if (!state.formdata) {
    return undefined;
  }

  const customemotion = state.formdata.customemotion;

  if (customemotion) {
    return capitalizeString(state.formdata.customemotion);
  }

  const emotionid = state.formdata.emotion;
  const baseemotionid = state.formdata.baseemotion;

  const baseemotions = state.baseemotions.filter(
    (emotion) => emotion.id === baseemotionid
  );

  if (baseemotions?.length > 0) {
    const emotions = baseemotions[0].emotions.filter(
      (emotion) => emotion.id === emotionid
    );

    if (emotions && emotions.length > 0) {
      return emotions[0].title;
    }
  }

  return undefined;
};

export const setOpacity = (hex, alpha) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, 0)}`;
export const adjustBrightness = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};
export const pad = (text, size) => {
  let s = "" + text;
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
};

export function useResponsive() {
  const [deviceWidth, setDeviceWidth] = useState(
    Dimensions.get("window").width
  );
  const [breakpoint, setBreakpoint] = useState();
  useEffect(() => {
    const updateLayout = () => {
      setDeviceWidth(Dimensions.get("window").width);
    };
    const listener = Dimensions.addEventListener("change", updateLayout);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    if (breakpoint !== "mobile" && deviceWidth < Layout.medium) {
      setBreakpoint("mobile");
    } else if (breakpoint !== "tablet" && deviceWidth >= Layout.medium) {
      return setBreakpoint("tablet");
    }
    return () => {};
  }, [deviceWidth]);

  const getResponsiveStyle = (breakpoints) => {
    if (breakpoints.mobile && deviceWidth < Layout.medium) {
      return breakpoints.mobile;
    }
    if (breakpoints.tablet && deviceWidth >= Layout.medium) {
      return breakpoints.tablet;
    }
  };
  return { breakpoint, getResponsiveStyle };
}
