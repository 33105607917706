import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../services/store";

const ChapterNavigation = () => {
  const navigation = useNavigation();
  const { state } = useGlobalState();

  const closeModal = () => {
    navigation.navigate("Main", { screen: "Education" });
  };

  const lastIndex = (arr, cond) => {
    if (!arr.length) return -1;
    if (!cond) return arr.length - 1;

    for (let i = arr.length - 1; i >= 0; --i) {
      if (cond(arr[i])) return i;
    }

    return -1;
  };
  const firstIndex = (arr, cond) => {
    if (!arr.length) return -1;
    if (!cond) return arr.length - 1;

    for (let i = 0; i < arr.length; i++) {
      if (cond(arr[i])) return i;
    }

    return -1;
  };
  const findChaptersForIndex = (index, isExercise = false) => {
    let payload = {
      chapter: null,
      subchapter: null,
      substep: null,
      index: index,
    };
    if (isExercise) {
      if (state.exercise_substeps && state.exercise_substeps.length > 0) {
        payload = state.exercise_substeps[index];
        payload.index = index;
      }
    } else {
      if (state.substeps && state.substeps.length > 0) {
        payload = state.substeps[index];
        payload.index = index;
      }
    }

    return payload;
  };
  const findIndexForSubChapter = (
    subchapterid,
    first = true,
    isExercise = false
  ) => {
    let index = 0;
    if (isExercise) {
      if (state.exercise_substeps && state.exercise_substeps.length > 0) {
        if (first) {
          index = firstIndex(state.exercise_substeps, (step) => {
            return step.subchapter.id === subchapterid;
          });
        } else {
          index = lastIndex(state.exercise_substeps, (step) => {
            return step.subchapter.id === subchapterid;
          });
        }
      }
    } else {
      if (state.substeps && state.substeps.length > 0) {
        if (first) {
          index = firstIndex(state.substeps, (step) => {
            return step.subchapter.id === subchapterid;
          });
        } else {
          index = lastIndex(state.substeps, (step) => {
            return step.subchapter.id === subchapterid;
          });
        }
      }
    }

    return index;
  };

  return {
    closeModal,
    findChaptersForIndex,
    findIndexForSubChapter,
  };
};

export default ChapterNavigation;
