import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import ChapterNavigation from "../../services/chapternavigation.service";
import { useGlobalState } from "../../services/store";
import Outline from "./Outline";

const EducationOutline = (props) => {
  const { state } = useGlobalState();
  const { findIndexForSubChapter } = ChapterNavigation();
  const [isExercise, setIsExercise] = useState(props.isExercise);
  const [outline, setOutline] = useState(state.outline);

  useEffect(() => {
    if (state.outline?.chapters?.length > 0) {
      if (props.isExercise) {
        setOutline(state.exercise);
      } else {
        setOutline(state.outline);
      }
      setIsExercise(props.isExercise);
    }
  }, [props.isExercise, state.exercise, state.outline]);

  const selectedSubchapter = isExercise
    ? state.selectedExerciseSubchapter
    : state.selectedSubchapter;

  const getSelectedSubchapter = () => {
    return isExercise
      ? state.selectedExerciseSubchapter
      : state.selectedSubchapter;
  };

  const openChapter = (chapterid) => {
    const index = findIndexForSubChapter(chapterid, true, isExercise);
    if (props.goto) {
      props.goto(index, true);
    }
  };

  return (
    <View
      style={{
        flex: -1,
        width: 400,
        position: "relative",
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            flexGrow: 1,
            paddingLeft: 50,
          }}
        >
          <View
            style={{
              paddingTop: 110,
            }}
          >
            <Outline
              outline={outline}
              openChapter={openChapter}
              selectedSubchapter={selectedSubchapter}
              isExercise={isExercise}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
export default EducationOutline;
