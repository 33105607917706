import React from "react";
import { Text, TouchableOpacity, View } from "react-native";

const Home = () => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <TouchableOpacity onPress={() => {}}>
        <Text>Gå till utbildning adw</Text>
      </TouchableOpacity>
    </View>
  );
};
export default Home;
