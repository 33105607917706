export const Colors = {
  transparent: "transparent",
  black: "#000000",
  white: "#FFFFFF",
  offWhite: "#FBFCFA",
  coral: "#E5674A",
  darkBlue: "#071D3E",
  darkBlueTransparent: "#28446988",
  lightBlueGrey: "#E7E8EB",
  lightGreenGrey: "#E4E7DB",
  grey: "#A6AEB9",
  lightGrey: "#CDD2D9",
  greyTransparent: "rgba(1, 13, 30, 0.55)",
};
