import React from "react";
import { Text, View } from "react-native";
import { Texts } from "../styles";

function CenteredParagraph(props) {
  let externalstyle = {};
  if (props.style) {
    externalstyle = props.style;
  }
  let maxWidth;
  if (props.unit === "percent") {
    maxWidth = `${props.maxWidth}%`;
  } else {
    maxWidth = props.maxWidth;
  }
  const styles = {
    paragraph: {
      marginTop: 20,
      marginBottom: 20,
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: maxWidth,
      ...externalstyle,
    },
    text: {
      ...Texts.body,
      ...externalstyle,
    },
  };
  return (
    <View style={styles.paragraph}>
      <Text style={styles.text}>{props.text}</Text>
    </View>
  );
}

export default CenteredParagraph;
