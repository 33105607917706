import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import FooterLogos from "../components/FooterLogos/FooterLogos";
import Login from "../components/Login";
import { Spacer } from "../components/Spacer";
import Base64 from "../helpers/base64";
import Renderer from "../helpers/renderer";
import {
  trackRegistrationScreenView,
  trackScreenView,
  useGlobalState,
} from "../services/store";
import i18n from "../services/translations";
import { Layout, Texts } from "../styles";

const Register = (props) => {
  const navigation = useNavigation();
  const navigateToEducation = () => {
    navigation.navigate("Education");
  };
  const { state } = useGlobalState();
  const [registrationToken, setRegistrationToken] = useState();

  const [viewContent, setViewContent] = useState([]);

  const getCustomerName = (thetoken) => {
    if (thetoken && thetoken.length > 0) {
      const decodedToken = Base64.base64_url_decode(thetoken);
      const parts = decodedToken.split(";");
      if (parts.length == 3) {
        return parts[0];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const registrationCallback = (status) => {
    let campain = "";
    if (props.route.params.c) {
      campain = props.route.params.c;
    }
    trackRegistrationScreenView(
      `registrationpage / ${status ? "success" : "fail"}`,
      state.user,
      getCustomerName(props.route.params.registrationtoken),
      campain
    );
  };

  useEffect(() => {
    if (
      props.route.params !== undefined &&
      props.route.params.registrationtoken !== undefined
    ) {
      let campain = "";
      if (props.route.params.c) {
        campain = props.route.params.c;
      }
      setRegistrationToken(props.route.params.registrationtoken);
      trackRegistrationScreenView(
        "registrationpage",
        state.user,
        getCustomerName(props.route.params.registrationtoken),
        campain
      );
    } else {
      trackScreenView("registrationpage", state.user);
    }
  }, []);

  useEffect(() => {
    const viewData = state.publiccontent.filter(
      (view) => view.id == "registration"
    );
    if (viewData && viewData.length > 0) {
      setViewContent(viewData[0].view_content);
    }
  }, [state.publiccontent, state.locale]);

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
          position: "absolute",
          top: 100,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            flexGrow: 1,
            padding: 20,
            paddingBottom: 30,
            alignItems: "stretch",
            justifyContent: "flex-start",
          }}
        >
          <View
            style={{
              alignSelf: "center",
              maxWidth: Layout.maxDesktop,
            }}
          >
            {viewContent.map((component, index) => Renderer(component, index))}

            <Spacer size="medium" />

            <Login
              registrationToken={registrationToken}
              isRegisterPage={true}
              loginCallback={navigateToEducation}
              registrationCallback={registrationCallback}
            />

            <Spacer size="small" />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Text style={Texts.body}>
                {i18n.t("labels.alreadyregistered1")}
              </Text>
              <TouchableOpacity onPress={navigateToEducation}>
                <Text
                  style={{ ...Texts.bodyBold, marginLeft: 5, marginRight: 5 }}
                >
                  {i18n.t("buttons.login")}
                </Text>
              </TouchableOpacity>
            </View>
            <Spacer size="large" />
          </View>
          <FooterLogos style={{ marginRight: -30, marginLeft: -30 }} />
        </ScrollView>
      </View>
    </View>
  );
};
export default Register;
