import { Colors } from "./colors";

export const Theme = {
  primary: Colors.coral,
  secondary: Colors.darkBlue,
  disabled: Colors.lightGrey,

  pageBg: Colors.offWhite,
  modalBg: Colors.white,
  modalOverlay: Colors.darkBlueTransparent,
  outlineBg: Colors.lightBlueGrey,

  textColor: Colors.darkBlue,
  textColorLight: Colors.white,
  textColorFaded: Colors.grey,

  borderColor: Colors.lightGrey,
  resultViewBorderColor: Colors.lightGreenGrey,

  subTitleBg: Colors.greyTransparent,
  subTitleColor: Colors.white,

  videoControlBg: Colors.coral,
  videoControlFg: Colors.white,
  skipControlBg: Colors.white,
  videoOverlay: Colors.greyTransparent,

  buttonHeight: 40,
  buttonRadius: 20,

  inputBorder: Colors.lightGrey,
  inputBg: Colors.white,
  inputLabelColor: Colors.darkBlue,
  inputRadius: 8,

  rangeThumbBg: Colors.coral,
  rangeThumbSize: 40,
};
