import { Video } from "expo-av";
import Hls from "hls.js/dist/hls.min.js";
import React, { useEffect, useRef, useState } from "react";
import { Platform, TouchableWithoutFeedback, View } from "react-native";
import { createElement } from "react-native-web";
import { animated, useSpring } from "@react-spring/native";
import { describeArc } from "../helpers/functions";
import { useGlobalState } from "../services/store";
import { Theme } from "../styles";
import { PlayControl } from "./VideoControl";

const AnimatedView = animated(View);

const VideoContent = (props) => {
  const NAV_HIDE = 2000;
  const { state, dispatch } = useGlobalState();
  const [navigationOpen, setNavigationOpen] = useState(true);
  const playSize = 100;
  const playCenter = 50;
  const playArc = 47.5;
  const [playOffsetX, setPlayOffsetX] = useState(60);
  const [playOffsetY, setPlayOffsetY] = useState(60);
  const [videoPaused, setVideoPaused] = useState(false);
  const [videoDone, setVideoDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [progressArc, setProgressArc] = useState("");
  const [videoHeight, setVideoHeight] = useState(0);
  const isMounted = useRef(false);
  const TIMEOUT = useRef(undefined);

  const showNavigation = () => {
    if (!videoDone) {
      setNavigationOpen(true);

      TIMEOUT.current = setTimeout(() => {
        if (isMounted.current) {
          setNavigationOpen(false);
        }
      }, NAV_HIDE);
    }
  };

  const hideNavigation = () => {
    if (TIMEOUT.current) {
      clearTimeout(TIMEOUT.current);
      TIMEOUT.current = undefined;
    }
    if (isMounted.current) {
      setNavigationOpen(false);
    }
  };
  const toggleNavigation = () => {
    if (navigationOpen) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };

  const containerWidth = useRef(0);
  const setContainerSize = (layout) => {
    const { x, y, width, height } = layout;

    containerWidth.current = width;
  };
  useEffect(() => {
    if (!isLoading) {
      setVideoDone(false);
      setVideoPaused(true);
      if (state.selectedIndex !== props.stepindex || !state.isInEducation) {
        stop();
      }
    }
  }, [state.selectedIndex, state.isInEducation, isLoading]);

  useEffect(() => {
    isMounted.current = true;
    let metaListener, timeListener;
    if (Platform.OS === "web") {
      metaListener = video.current?.addEventListener(
        "loadedmetadata",
        function () {
          if (isLoading) {
            setIsLoading(false);
          }
          const ratio = video.current?.videoWidth
            ? video.current.videoHeight / video.current.videoWidth
            : 9 / 16;
          setVideoHeight(Math.floor(containerWidth.current * ratio));
        }
      );
      timeListener = video.current?.addEventListener("timeupdate", function () {
        setWebVideoStatus();
      });
    }

    _initVideoPlayer();

    return () => {
      isMounted.current = false;
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
      if (metaListener) metaListener.remove();
      if (timeListener) timeListener.remove();
    };
  }, []);

  const _initVideoPlayer = () => {
    if (props.url && video.current) {
      if (
        video.current.canPlayType("application/vnd.apple.mpegurl") ||
        props.url.indexOf(".m3u8") === -1
      ) {
        video.current.src = props.url;
      } else if (Hls.isSupported()) {
        hlsRef.current = new Hls();
        hlsRef.current.loadSource(props.url);
        hlsRef.current.attachMedia(video.current);
        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
          video.current.play();
        });
      }
    }
  };

  const video = useRef(null);
  const hlsRef = useRef(undefined);

  const setPlayButtonOffset = (layout) => {
    const { x, y, width, height } = layout;
    setPlayOffsetY(height);
    setPlayOffsetX(width);
  };

  const createWebVideo = (attrs) => {
    return createElement("video", attrs);
  };

  const toggleVideo = () => {
    if (videoDone) {
      replay();
    } else {
      !videoPaused ? pause() : play();
    }
    if (videoPaused) {
      hideNavigation();
    } else {
      showNavigation();
    }
  };

  const play = async () => {
    if (Platform.OS === "web") {
      const playPromise = video.current?.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            setVideoDone(false);
            setVideoPaused(false);
          })
          .catch(function (error) {
            setVideoDone(false);
            setVideoPaused(true);
          });
      }
    } else {
      await video.current?.playAsync();
      setVideoDone(false);
      setVideoPaused(false);
    }
  };
  const pause = async () => {
    if (Platform.OS === "web") {
      video.current?.pause();
    } else {
      await video.current?.pauseAsync();
    }
    setVideoPaused(true);
  };
  const replay = async () => {
    if (Platform.OS === "web") {
      if (video.current) video.current.currentTime = 0;
      video.current?.play();
    } else {
      await video.current?.replayAsync();
    }
    setVideoDone(false);
    setVideoPaused(false);
    hideNavigation();
  };
  const stop = async () => {
    if (Platform.OS === "web") {
      if (video.current) video.current.currentTime = 0;
      video.current?.pause();
    } else {
      await video.current?.stopAsync();
    }
    setVideoPaused(true);
  };
  const endVideo = () => {
    setVideoDone(true);
    pause();
    showNavigation();
  };
  const setWebVideoStatus = () => {
    if (isMounted.current) {
      if (video.current?.currentTime > 0) {
        if (video.current?.ended) {
          endVideo();
        }

        const progArc = describeArc(
          playCenter,
          playCenter,
          playArc,
          0,
          (video.current?.currentTime / video.current?.duration) * 360
        );
        setProgressArc(progArc);
      } else {
        setProgressArc("");
      }
    }
  };

  const setVideoStatus = (status) => {
    if (isMounted.current) {
      if (status.isLoaded && isLoading) {
        setIsLoading(false);
        video.current?.setProgressUpdateIntervalAsync(1000);
        if (status.durationMillis > 0) {
          if (status.positionMillis === status.durationMillis) {
            setVideoDone(true);
            showNavigation();
          }

          const progArc = describeArc(
            playCenter,
            playCenter,
            playArc,
            0,
            (status.positionMillis / status.durationMillis) * 360
          );
          setProgressArc(progArc);
        } else {
          setProgressArc("");
        }
      }
    }
  };
  const interpolationStyles = {
    playButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 40,
      transform: [
        { translateX: -playOffsetX / 2 },
        { translateY: -(playSize / 2 + (playOffsetY - playSize)) },
      ],
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: Theme.videoOverlay,
      opacity: 0.4,
      zIndex: 5,
    },
  };
  const headingAnimationStyle = useSpring({
    opacity: navigationOpen || videoPaused ? 1 : 0,
    config: {
      mass: 1,
      tension: 700,
      friction: 20,
      clamp: true,
    },
  });
  return (
    <View
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
      onLayout={(event) => {
        setContainerSize(event.nativeEvent.layout);
      }}
    >
      <TouchableWithoutFeedback onPress={toggleNavigation}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                alignItems: "stretch",
              }}
            >
              {Platform.OS === "web" ? (
                createWebVideo({
                  ref: video,
                  src: props.url,
                  preload: "metadata",
                  autoPlay: false,
                  playsInline: true,
                  style: {
                    width: "100%",
                    height: videoHeight.current,
                    objectFit: "contain",
                    margin: 0,
                    padding: 0,
                  },
                })
              ) : (
                <Video
                  ref={video}
                  style={{
                    width: Platform.OS === "web" ? "100vw" : "100%",
                  }}
                  useNativeControls={false}
                  resizeMode="contain"
                  onPlaybackStatusUpdate={(status) => setVideoStatus(status)}
                />
              )}
              {(videoPaused || videoDone) && (
                <View style={interpolationStyles.overlay}></View>
              )}
              <AnimatedView
                style={{
                  ...interpolationStyles.playButton,
                  ...headingAnimationStyle,
                }}
              >
                <View
                  style={{
                    flex: -1,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onLayout={(event) => {
                    setPlayButtonOffset(event.nativeEvent.layout);
                  }}
                >
                  <PlayControl
                    type={
                      videoDone
                        ? "repeat"
                        : !videoPaused.current
                        ? "pause"
                        : "play"
                    }
                    bgColor={Theme.videoControlBg}
                    fgColor={Theme.videoControlFg}
                    progressArc={videoDone ? undefined : progressArc}
                    onPress={toggleVideo}
                  />
                </View>
              </AnimatedView>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};
export default VideoContent;
