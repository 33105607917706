import React, { useEffect, useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import StepProgress from "../components/StepProgress";
import { Layout, Theme } from "../styles";

const BottomChapterNav = (props) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const notDoneColor = Theme.disabled;
  const doneColor = Theme.primary;

  const nextStyle = {
    borderRadius: 40,
    backgroundColor: props.isFinalStep
      ? Theme.primary
      : props.isDone
      ? doneColor
      : notDoneColor,
    height: 40,
    width: 40,
    flex: -1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <View style={styles.container}>
      <View style={styles.bottomNav}>
        <View style={styles.bottomNavInner}>
          <View style={styles.hidden}></View>
          <StepProgress isDark={props.isDark} isExercise={props.isExercise} />
          <TouchableOpacity style={nextStyle} onPress={() => props.gotoNext()}>
            <Icon
              color={Theme.textColorLight}
              name={props.isFinalStep ? "close" : "caret-forward-outline"}
              size={20}
              type="ionicon"
              style={{ paddingLeft: 2 }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
var styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },

  bottomNav: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  bottomNavInner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },

  hidden: {
    ...Layout.hiddenContainer,
    width: 40,
    height: 40,
  },
});
export default BottomChapterNav;
