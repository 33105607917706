export const base = {
  verticalAlign: "center",
};

export const hiddenContainer = {
  opacity: 0,
};

export const minContent = 280;
export const maxContent = 1024;
export const maxDesktop = 700;
export const maxContentSmall = 700;
export const smallColumnDesktop = 520;
export const minContentDesktop = 550;
export const small = 576;
export const medium = 960;
export const large = 1366;
export const xlarge = 1920;
