import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import Base64 from "../helpers/base64";
import AuthService from "../services/auth.service";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Inputs, Texts, Theme } from "../styles";
import { PrimaryButton } from "./Buttons";
import { Spacer } from "./Spacer";

const Login = (props) => {
  const { dispatch } = useGlobalState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);

  const onChangeUsername = (e) => {
    const username = e; // e.target.value;
    setUsername(username);
  };

  const getCustomerName = (thetoken) => {
    if (thetoken && thetoken.length > 0) {
      const decodedToken = Base64.base64_url_decode(thetoken);
      const parts = decodedToken.split(";");
      if (parts.length == 3) {
        return parts[2];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (props.registrationToken && props.registrationToken.length > 0) {
      const custname = getCustomerName(props.registrationToken);
      if (custname.length > 0) {
        setToken(props.registrationToken);
      }
    }
  }, [props.registrationToken]);

  const onChangePassword = (e) => {
    const password = e; //  e.target.value;
    setPassword(password);
  };
  const onChangeCode = (e) => {
    setToken(e);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    if (props.isRegisterPage) {
      const senttoken = token === "" ? "dummy" : token;

      AuthService.register(username, password, senttoken).then(
        (user) => {
          setLoading(false);
          setErrorMessage(undefined);
          if (
            props.isRegisterPage &&
            props.registrationCallback !== undefined
          ) {
            props.registrationCallback(true);
          }

          if (props.isRegisterPage && props.loginCallback !== undefined) {
            props.loginCallback();
          }

          dispatch({ type: "SET_USER", payload: user });
        },
        (error) => {
          setLoading(false);
          if (
            props.isRegisterPage &&
            props.registrationCallback !== undefined
          ) {
            props.registrationCallback(false);
          }
          setErrorMessage(
            i18n.t(`messages.${error}`, {
              defaults: [{ scope: "messages.generic" }],
            })
          );
        }
      );
    } else {
      AuthService.login(username, password).then(
        (user) => {
          setLoading(false);
          setErrorMessage(undefined);
          dispatch({ type: "SET_USER", payload: user });
        },
        (error) => {
          setLoading(false);
          setErrorMessage(
            i18n.t(`messages.${error}`, {
              defaults: [{ scope: "messages.generic" }],
            })
          );
        }
      );
    }
  };

  return (
    <View style={styles.container}>
      <Text style={Inputs.label}>{i18n.t("labels.email")}</Text>
      <Spacer size="small" />
      <TextInput
        style={Inputs.inputField}
        type="email"
        placeholder={i18n.t("inputs.email")}
        autoCompleteType="email"
        value={username}
        onChangeText={onChangeUsername}
      />

      <Spacer size="medium" />

      <Text style={Inputs.label}>
        {props.isRegisterPage
          ? i18n.t("labels.registerpassword")
          : i18n.t("labels.password")}
      </Text>
      <Spacer size="small" />
      <TextInput
        style={Inputs.inputField}
        secureTextEntry={true}
        placeholder={i18n.t("inputs.password")}
        onChangeText={onChangePassword}
      />

      {props.isRegisterPage && (
        <>
          <Spacer size="medium" />
          <Text style={Inputs.label}>{i18n.t("labels.customercode")}</Text>
          <Spacer size="small" />
          <TextInput
            secureTextEntry={false}
            style={Inputs.inputField}
            value={token}
            onChangeText={onChangeCode}
            placeholder={i18n.t("inputs.customercode")}
          />
        </>
      )}

      <Spacer size="large" />

      <PrimaryButton
        onPress={handleLogin}
        text={
          props.isRegisterPage
            ? i18n.t("buttons.register")
            : i18n.t("buttons.login")
        }
        loading={loading}
      />

      {errorMessage && (
        <>
          <Spacer size="small" />
          <Text style={Texts.body}>{errorMessage}</Text>
          <Spacer size="small" />
        </>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: 400,
  },
});

export default Login;
