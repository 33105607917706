import React, { useState } from "react";
import { Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Renderer, { isComponent } from "../../helpers/renderer";
import { useGlobalState } from "../../services/store";
import i18n from "../../services/translations";
import { Texts, Theme } from "../../styles";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import RatingScaleQuestion from "./RatingScaleQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";

const useAnswers = (questions = []) => {
  const [answers, setAnswers] = useState({});

  const setAnswer = (answer) => {
    setAnswers({ ...answers, [answer.id]: answer.value });
  };

  const isValid = !questions.some(
    (question) => answers[question.airtableField] === undefined
  );

  return {
    answers,
    setAnswer,
    isValid,
  };
};

const SurveySection = ({ data, onNext, onPlayAgain, getResponsiveStyle }) => {
  const { answers, setAnswer, isValid } = useAnswers(data.questions);
  const { state } = useGlobalState();
  const insets = useSafeAreaInsets();
  const doneContent = data.next ?? [];
  const stepType = state?.selectedSubstep?.type;

  return (
    <>
      <View
        style={{
          ...styles.contentWrapper,
          ...getResponsiveStyle({ mobile: { paddingTop: 35 } }),
        }}
      >
        <Text
          style={{
            ...Texts.surveyHeader,
            color: Theme.textColor,
            marginBottom: 20,
          }}
        >
          {data.header}
        </Text>

        <Text
          style={{
            ...Texts.surveyIntro,
            color: Theme.textColor,
            opacity: 0.5,
            marginBottom: 20,
          }}
        >
          {data.intro}
        </Text>

        {data.questions.map((question, i) => {
          switch (question.data.type) {
            case "single-choice":
              return (
                <SingleChoiceQuestion
                  key={i}
                  question={question}
                  answers={answers}
                  setAnswer={setAnswer}
                  getResponsiveStyle={getResponsiveStyle}
                />
              );
            case "rating-scale":
              return (
                <RatingScaleQuestion
                  key={i}
                  question={question}
                  answers={answers}
                  setAnswer={setAnswer}
                />
              );
            default:
              break;
          }
        })}
      </View>

      <View
        style={[
          styles.spacer,
          { ...getResponsiveStyle({ mobile: { minHeight: 0 } }) },
        ]}
      ></View>

      <View
        style={[
          styles.nextWrapper,
          { ...getResponsiveStyle({ mobile: { display: "none" } }) },
        ]}
      >
        {doneContent.map((component, index) =>
          Renderer(component, index, { textAlign: "right" })
        )}
        <View style={styles.buttonWrapper}>
          {stepType === "video" && (
            <SecondaryButton
              text={i18n.t("buttons.playagain")}
              style={{ marginTop: 20, marginRight: 20 }}
              onPress={() => onPlayAgain()}
            />
          )}
          <PrimaryButton
            text={i18n.t("buttons.continue")}
            disabled={!isValid}
            style={{ marginTop: 20 }}
            onPress={() => onNext(answers)}
          />
        </View>
      </View>

      <View
        style={[
          styles.nextWrapperMobile,
          {
            display: "none",
            ...getResponsiveStyle({ mobile: { display: "flex" } }),
          },
        ]}
      >
        {doneContent.map((component, index) =>
          Renderer(component, index, {
            textAlign: "center",
            color: Theme.textColorLight,
          })
        )}
        <View style={styles.buttonWrapper}>
          {stepType === "video" && (
            <SecondaryButton
              color={Theme.textColorLight}
              text={i18n.t("buttons.playagain")}
              style={{
                marginTop: 15,
                marginRight: 20,
                marginBottom: insets.bottom,
              }}
              onPress={() => onPlayAgain()}
            />
          )}
          <PrimaryButton
            text={i18n.t("buttons.continue")}
            disabled={!isValid}
            style={{ marginTop: 15, marginBottom: insets.bottom }}
            onPress={() => onNext(answers)}
          />
        </View>
      </View>
    </>
  );
};

const styles = {
  spacer: {
    display: "flex",
    flexGrow: 1,
    minHeight: 50,
  },
  contentWrapper: {
    padding: 20,
  },
  nextWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 20,
  },
  nextWrapperMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 28,
    width: "100%",
    backgroundColor: Theme.secondary,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default SurveySection;
