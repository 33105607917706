import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { getColorizedEmotion } from "../../helpers/svg-assets";
import { Texts, Theme } from "../../styles";
import { Spacer } from "../Spacer";

export const ResultViewSection = (props) => {
  const section = props.section;

  return (
    <View style={styles.container}>
      <View style={styles.leftCol}>
        <Image
          style={styles.image}
          source={{
            uri: section.image?.url,
          }}
        />
        <Spacer size="small" />
        <View style={[styles.line, { backgroundColor: section.color }]}></View>
      </View>
      <Spacer size={30} />
      <View style={styles.rightCol}>
        <Text style={{ ...Texts.resultBoxHeading }}>{section.title}</Text>
        <Spacer size={30} />
        {section.subSections.map((subSection, index) => (
          <React.Fragment key={index}>
            <Text style={{ ...Texts.resultBoxSubHeading }}>
              {subSection.subTitle}
            </Text>
            <Spacer size={5} />
            <View style={styles.contentRow}>
              {subSection.emotionId && (
                <View
                  style={{
                    height: 40,
                    width: 40,
                    marginRight: 10,
                  }}
                >
                  {getColorizedEmotion(subSection.emotionId, Theme.secondary)}
                </View>
              )}

              {subSection.content.map((text, index) => (
                <Text key={index} style={{ ...Texts.resultBoxText }}>
                  {text}
                </Text>
              ))}
            </View>
            <Spacer size={30} />
          </React.Fragment>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflow: "hidden",
  },
  leftCol: {
    display: "flex",
    alignItems: "center",
  },
  rightCol: {
    flex: 1,
    overflow: "hidden",
  },
  line: {
    width: 2,
    flex: 1,
  },
  image: {
    width: 32,
    height: 32,
    resizeMode: "contain",
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
