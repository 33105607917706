import React from "react";
import { Button, StyleSheet, Text, View } from "react-native";

const Profile = (props) => {
  return (
    <View style={styles.container}>
      <Text>This is the profile screen</Text>
      <Button
        title="Go to Educaction"
        onPress={() => props.navigation.navigate("Education")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
Profile["navigationOptions"] = (screenProps) => ({
  title: "Profile",
});
Profile["path"] = "profile";
export default Profile;
