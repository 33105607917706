import React from "react";
import { View } from "react-native";

const sizes = {
  small: 10,
  medium: 20,
  large: 40,
};

export const Spacer = (props) => {
  const value =
    typeof props.size === "string"
      ? sizes[props.size]
      : props.size ?? sizes.medium;

  return <View style={{ width: value, height: value }} />;
};
