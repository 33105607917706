import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Texts, Theme } from "../styles";
import { Spacer } from "./Spacer";

const BeginBar = (props) => {
  const { state } = useGlobalState();
  let barStyle = {};
  let buttonStyle = {};
  const insets = useSafeAreaInsets();

  if (props.layout === "mobile") {
    barStyle = {
      flex: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: 80 + insets.bottom,
      paddingBottom: insets.bottom,
      backgroundColor: Theme.secondary,
    };
    buttonStyle = {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    };
  }
  if (props.layout === "tablet") {
    barStyle = {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "45%",
    };
    buttonStyle = {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    };
  }

  return (
    <View style={barStyle}>
      <View style={buttonStyle}>
        <View
          style={{
            flex: -1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              ...Texts.bodyBold,
              color: Theme.textColorLight,
            }}
          >
            {i18n.t("buttons.begin")}
          </Text>
        </View>
        <Spacer size="small" />
        <TouchableOpacity
          onPress={props.openChapter}
          style={{
            flexDirection: "column",
            justifyContent: "center",
          }}
          disabled={state.outline?.chapters?.length === 0}
        >
          <View
            style={{
              borderRadius: 40,
              height: 40,
              width: 40,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Theme.primary,
            }}
          >
            <Icon
              color={Theme.secondary}
              name="arrow-forward"
              size={20}
              type="material"
            />
          </View>
        </TouchableOpacity>
        <Spacer size="medium" />
      </View>
    </View>
  );
};
export default BeginBar;
