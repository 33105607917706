import React, { useEffect, useRef, useState } from "react";
import { Animated, Dimensions, Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Theme } from "../../styles";
import SurveySection from "./SurveySection";
import { useSurvey } from "./useSurvey";

const Survey = ({ onContinue, onPlayAgain, getResponsiveStyle }) => {
  const {
    survey,
    isSurveyOpen,
    surveySectionId,
    closeSurvey: _closeSurvey,
    saveSurveyAnswers,
  } = useSurvey();
  const [isOpen, setIsOpen] = useState(isSurveyOpen);
  const [answers, setAnswers] = useState({});
  const [maxHeight, setMaxHeight] = useState(Dimensions.get("window").height);
  const surveyTopAnim = useRef(new Animated.Value(maxHeight)).current;
  const surveyOverlayOpacity = useRef(new Animated.Value(0)).current;
  const insets = useSafeAreaInsets();

  useEffect(() => {
    const updateLayout = () => setMaxHeight(Dimensions.get("window").height);
    const layoutListener = Dimensions.addEventListener("change", updateLayout);

    return () => {
      layoutListener.remove();
    };
  }, []);

  useEffect(() => {
    if (isSurveyOpen) {
      openSurvey();
    } else {
      closeSurvey();
    }
  }, [isSurveyOpen]);

  const openSurvey = () => {
    setIsOpen(true);
    Animated.sequence([
      Animated.timing(surveyOverlayOpacity, {
        toValue: 1,
        duration: 150,
        useNativeDriver: false,
      }),
      Animated.timing(surveyTopAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }),
    ]).start();
  };

  const closeSurvey = () => {
    Animated.sequence([
      Animated.timing(surveyTopAnim, {
        toValue: maxHeight,
        duration: 300,
        useNativeDriver: false,
      }),
      Animated.spring(surveyOverlayOpacity, {
        toValue: 0,
        duration: 150,
        useNativeDriver: false,
      }),
    ]).start(() => setIsOpen(false));
  };

  const surveySection = survey.find(
    (section) => section.sectionId === surveySectionId
  );

  if (!isOpen || !surveySection) {
    return null;
  }

  return (
    <>
      <Animated.View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: Theme.modalOverlay,
          opacity: surveyOverlayOpacity,
        }}
      ></Animated.View>

      <Animated.View
        style={[
          {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            padding: 50,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...getResponsiveStyle({
              mobile: {
                padding: 0,
                paddingTop: insets.top,
              },
            }),
          },
          {
            transform: [{ translateY: surveyTopAnim }],
          },
        ]}
      >
        <View
          style={{
            backgroundColor: Theme.modalBg,
            padding: 30,
            borderRadius: 12,
            position: "relative",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            width: 668,
            display: "flex",
            overflow: Platform.OS === "web" ? "auto" : "scroll",
            ...getResponsiveStyle({
              mobile: {
                padding: 0,
                borderRadius: 0,
                width: "100%",
                flex: 1,
              },
            }),
          }}
        >
          <SurveySection
            data={surveySection}
            onNext={(newAnswers) => {
              setAnswers({ ...answers, ...newAnswers });
              saveSurveyAnswers(surveySectionId, newAnswers);
              _closeSurvey();
              onContinue();
            }}
            onPlayAgain={() => {
              onPlayAgain();
              _closeSurvey();
            }}
            getResponsiveStyle={getResponsiveStyle}
          />
        </View>
      </Animated.View>
    </>
  );
};

export default Survey;
