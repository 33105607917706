import { Platform, StyleSheet } from "react-native";
import * as Inputs from "./inputs";
import * as Layout from "./layout";
import * as Texts from "./texts";
import { Theme } from "./theme";

const GlobalStyles = StyleSheet.create({
  droidSafeArea: {
    flex: 1,
    paddingTop: Platform.OS === "android" ? 25 : 0,
  },
});

export { Inputs, Layout, Texts, Theme, GlobalStyles };
