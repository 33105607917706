import React from "react";
import { Text, View } from "react-native";
import { getTranslation } from "../helpers/functions";
import { useGlobalState } from "../services/store";
import { Texts } from "../styles";

function UnderlineIngress(props) {
  const { state } = useGlobalState();
  let ingress = getTranslation(props.ingress, state);

  let externalstyle = {};
  if (props.style) {
    externalstyle = props.style;
  }
  const styles = {
    ingress: {
      ...Texts.h3,
      marginTop: 20,
      marginBottom: 20,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      ...externalstyle,
    },
    underline: {
      flex: -1,
      ...Texts.h3,
      marginRight: 15,
      textDecorationLine: "underline",
      ...externalstyle,
      flexWrap: "no-wrap",
    },
    text: {
      flex: 1,
      ...Texts.h3,
      ...externalstyle,
    },
  };
  return (
    <View style={styles.ingress}>
      <Text style={styles.underline}>{props.underlined}</Text>
      <Text style={styles.text}>{ingress}</Text>
    </View>
  );
}

export default UnderlineIngress;
