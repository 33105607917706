import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Animated,
  I18nManager,
  PanResponder,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { StateContext } from "../services/store";
import i18n from "../services/translations";
import { Layout, Texts, Theme } from "../styles";

const useNativeDriver = false; // because of RN #13377

export default class Swiper extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);

    this._onLayout = this._onLayout.bind(this);
    this._fixState = this._fixState.bind(this);

    this.goToPrev = this.goToPrev.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.goTo = this.goTo.bind(this);

    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      activeIndex: props.from,
      pan: new Animated.ValueXY(),
      opacity: new Animated.Value(1),
    };

    this._animatedValueX = 0;
    this._animatedValueY = 0;

    this._panResponder = PanResponder.create(this._getPanResponderCallbacks());
  }

  children = (() => React.Children.toArray(this.props.children))();
  count = (() => this.children.length)();

  refresh() {
    this.children = (() => React.Children.toArray(this.props.children))();
    this.count = (() => this.children.length)();
  }

  goToNext() {
    this._goToNeighboring();
  }

  goToPrev() {
    this._goToNeighboring(true);
  }

  goTo(index = 0) {
    const delta = index - this.getActiveIndex();
    if (delta) {
      this._fixAndGo(delta);
    }
  }

  getActiveIndex() {
    return this.state.activeIndex;
  }

  // stop public methods

  _goToNeighboring(toPrev = false) {
    this._fixAndGo(toPrev ? -1 : 1);
  }

  componentDidMount() {
    this.state.pan.x.addListener(({ value }) => (this._animatedValueX = value));
    this.state.pan.y.addListener(({ value }) => (this._animatedValueY = value));
  }

  componentWillUnmount() {
    this.state.pan.x.removeAllListeners();
    this.state.pan.y.removeAllListeners();
  }

  _getPanResponderCallbacks() {
    return {
      onPanResponderTerminationRequest: () => false,
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: (e, gestureState) => {
        const { gesturesEnabled, vertical, minDistanceToCapture } = this.props;

        if (!gesturesEnabled()) {
          return false;
        }

        this.props.onAnimationStart &&
          this.props.onAnimationStart(this.getActiveIndex());

        const allow =
          Math.abs(vertical ? gestureState.dy : gestureState.dx) >
          minDistanceToCapture;
        return allow;
      },
      onPanResponderGrant: () => this._fixState(),
      onPanResponderMove: Animated.event(
        [
          null,
          this.props.vertical
            ? { dy: this.state.pan.y }
            : { dx: this.state.pan.x },
        ],
        { useNativeDriver: false }
      ),
      onPanResponderRelease: (e, gesture) => {
        const { vertical, minDistanceForAction } = this.props;
        const { width, height } = this.state;

        const correction = vertical
          ? gesture.moveY - gesture.y0
          : gesture.moveX - gesture.x0;

        if (
          Math.abs(correction) <
          (vertical ? height : width) * minDistanceForAction
        ) {
          this._spring({ x: 0, y: 0 });
        } else {
          this._changeIndex(
            correction > 0
              ? !vertical && I18nManager.isRTL
                ? 1
                : -1
              : !vertical && I18nManager.isRTL
              ? -1
              : 1
          );
        }
      },
    };
  }

  _spring(toValue) {
    const { springConfig, onAnimationEnd, animate } = this.props;
    const { activeIndex, opacity } = this.state;
    if (animate) {
      Animated.spring(this.state.pan, {
        ...springConfig,
        toValue,
        friction: 15,
        tension: 20,
        useNativeDriver, // false, see top of file
      }).start(() => onAnimationEnd && onAnimationEnd(activeIndex));
    } else {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 150,
        useNativeDriver,
      }).start((finished) => {
        this.state.pan.setValue(toValue);
        Animated.timing(opacity, {
          toValue: 1,
          duration: 150,
          useNativeDriver,
        }).start();
      });
    }
  }

  _fixState() {
    const { vertical } = this.props;
    const { width, height, activeIndex } = this.state;
    this._animatedValueX = vertical
      ? 0
      : width * activeIndex * (I18nManager.isRTL ? 1 : -1);
    this._animatedValueY = vertical ? height * activeIndex * -1 : 0;
    this.state.pan.setOffset({
      x: this._animatedValueX,
      y: this._animatedValueY,
    });
    this.state.pan.setValue({ x: 0, y: 0 });
  }

  _fixAndGo(delta) {
    this._fixState();
    this.props.onAnimationStart &&
      this.props.onAnimationStart(this.getActiveIndex());
    this._changeIndex(delta);
  }

  _changeIndex(delta = 1) {
    const { loop, vertical } = this.props;
    const { width, height, activeIndex } = this.state;

    let toValue = { x: 0, y: 0 };
    let skipChanges = !delta;
    let calcDelta = delta;

    if (activeIndex <= 0 && delta < 0) {
      skipChanges = !loop;
      calcDelta = this.count + delta;
    } else if (activeIndex + 1 >= this.count && delta > 0) {
      skipChanges = !loop;
      calcDelta = -1 * activeIndex + delta - 1;
    }

    if (skipChanges) {
      return this._spring(toValue);
    }

    let index = activeIndex + calcDelta;
    this.setState({ activeIndex: index });

    if (vertical) {
      toValue.y = height * -1 * calcDelta;
    } else {
      toValue.x = width * (I18nManager.isRTL ? 1 : -1) * calcDelta;
    }
    this._spring(toValue);

    this.props.onIndexChanged && this.props.onIndexChanged(index);
  }

  _onLayout({
    nativeEvent: {
      layout: { x, y, width, height },
    },
  }) {
    this.setState({ x, y, width, height }, () => this._fixState());
  }

  render() {
    const { pan, x, y, width, height, opacity } = this.state;

    const {
      vertical,
      positionFixed,
      containerStyle,
      innerContainerStyle,
      swipeAreaStyle,
      controlsEnabled,
      isDone,
      isDark,
      isFinalStep,
      gotoNextExternal,
      slideWrapperStyle,
      controlsBottomPos,
    } = this.props;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        <View
          style={StyleSheet.flatten([styles.root, containerStyle])}
          onLayout={this._onLayout}
        >
          <View
            style={StyleSheet.flatten([
              styles.container(positionFixed, x, y, width, height),
              innerContainerStyle,
            ])}
          >
            <Animated.View
              style={StyleSheet.flatten([
                styles.swipeArea(vertical, this.count, width, height),
                swipeAreaStyle,
                { opacity: opacity },
                {
                  transform: [{ translateX: pan.x }, { translateY: pan.y }],
                },
              ])}
              {...this._panResponder.panHandlers}
            >
              {this.children.map((el, i) => (
                <View
                  key={`swiperview_${i}`}
                  style={StyleSheet.flatten([
                    { width, height },
                    slideWrapperStyle,
                    { zIndex: 0 },
                  ])}
                >
                  {i < this.state.activeIndex + 1 &&
                    i > this.state.activeIndex - 1 &&
                    el}
                </View>
              ))}
            </Animated.View>
          </View>
          {controlsEnabled && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                margin: 20,
                marginLeft: 60,
                position: "absolute",

                bottom: controlsBottomPos,
                left: 0,
                right: 0,
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  maxWidth: Layout.maxContent,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderRadius: 20,
                      backgroundColor: Theme.disabled,
                      height: 40,
                      width: 40,
                      zIndex: 20,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={this.goToPrev}
                  >
                    <Icon
                      color={Theme.textColorLight}
                      name="caret-back-outline"
                      size={20}
                      type="ionicon"
                      style={{ paddingRight: 1 }}
                    />
                  </TouchableOpacity>
                  <Text
                    style={{
                      ...Texts.bodyBold,
                      marginLeft: 10,
                    }}
                  >
                    {i18n.t("buttons.back")}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      ...Texts.bodyBold,
                      marginRight: 10,
                    }}
                  >
                    {isFinalStep
                      ? i18n.t("buttons.survey")
                      : i18n.t("buttons.next")}
                  </Text>
                  <TouchableOpacity
                    style={{
                      borderRadius: 40,
                      backgroundColor:
                        isFinalStep || isDone ? Theme.primary : Theme.disabled,
                      height: 40,
                      width: 40,
                      zIndex: 20,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={gotoNextExternal}
                  >
                    <Icon
                      color={Theme.textColorLight}
                      name={isFinalStep ? "close" : "caret-forward-outline"}
                      size={20}
                      type="ionicon"
                      style={{ paddingLeft: 2 }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

Swiper.propTypes = {
  vertical: PropTypes.bool,
  from: PropTypes.number,
  loop: PropTypes.bool,
  timeout: PropTypes.number,
  gesturesEnabled: PropTypes.func,
  springConfig: PropTypes.object,
  minDistanceToCapture: PropTypes.number, // inside ScrollView
  minDistanceForAction: PropTypes.number,

  onAnimationStart: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  onIndexChanged: PropTypes.func,

  positionFixed: PropTypes.bool, // Fix safari vertical bounces
  containerStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  innerContainerStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  swipeAreaStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  slideWrapperStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  controlsEnabled: PropTypes.bool,
  firstSelected: PropTypes.bool,
  isDone: PropTypes.bool,
  controlsBottomPos: PropTypes.number,
  isFinalStep: PropTypes.bool,
  isDark: PropTypes.bool,
  gotoNextExternal: PropTypes.func,
  theme: PropTypes.object,
  animate: PropTypes.bool,
};

Swiper.defaultProps = {
  vertical: false,
  from: -1,
  loop: false,
  timeout: 0,
  gesturesEnabled: () => true,
  minDistanceToCapture: 10,
  minDistanceForAction: 0.35,
  positionFixed: false,
  controlsEnabled: false,
  animate: true,
};

const styles = {
  root: {
    flex: 1,
    backgroundColor: "transparent",
  },
  // Fix web vertical scaling (like expo v33-34)
  container: (positionFixed, x, y, width, height) => ({
    backgroundColor: "transparent",
    // Fix safari vertical bounces
    position: positionFixed ? "fixed" : "relative",
    overflow: "hidden",
    top: positionFixed ? y : 0,
    left: positionFixed ? x : 0,
    width,
    height,
    justifyContent: "space-between",
  }),
  swipeArea: (vertical, count, width, height) => ({
    position: "absolute",
    zIndex: 0,
    top: 0,
    left: 0,
    width: vertical ? width : width * count,
    height: vertical ? height * count : height,
    flexDirection: vertical ? "column" : "row",
  }),
};
