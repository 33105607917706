import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Constants from "expo-constants";

const API_URL = Constants.manifest.extra.API_SERVER + "/wp-json/jwt-auth/v1/";

const register = (username, password, custom_auth) => {
  return new Promise((resolve, reject) => {
    let data;
    let postData = {
      username,
      password,
    };
    if (custom_auth !== undefined) {
      postData.custom_auth = custom_auth;
    }
    axios.post(API_URL + "token", postData).then(
      (response) => {
        data = response.data;
        if (data.success) {
          try {
            const user = {
              token: data.data.token,
              usr: username,
              uid: data.data.uid,
              customer: data.data.customer,
            };
            AsyncStorage.setItem("user", JSON.stringify(user)).then(() => {
              resolve(user);
            });
          } catch (e) {
            AsyncStorage.removeItem("user", null).then(() => {
              reject("storageerror");
            });
          }
        } else {
          reject(data.code);
        }
      },
      () => {
        AsyncStorage.removeItem("user", null).then(() => {
          reject("communicationerror");
        });
      }
    );
  });
};
const login = (username, password) => {
  return register(username, password, undefined);
};

const validateToken = (auth) => {
  return new Promise((resolve, reject) => {
    axios.post(API_URL + "token/validate", {}, { headers: auth }).then(
      (response) => {
        if (response.data && response.data.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      (error) => {
        reject(false);
      }
    );
  });
};
const logout = () => {
  return new Promise((resolve, reject) => {
    try {
      AsyncStorage.removeItem("user").then(() => {
        resolve(true);
      });
    } catch (exception) {
      reject("failed to remove user data");
    }
  });
};

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    try {
      AsyncStorage.getItem("user").then((user) => {
        if (user !== null) {
          resolve(JSON.parse(user));
        } else {
          resolve(null);
        }
      });
    } catch (e) {
      reject(null);
    }
  });
};
const validateCurrentUser = () => {
  return new Promise((resolve, reject) => {
    try {
      getCurrentUser().then(
        (user) => {
          if (user && user.token) {
            validateToken({ Authorization: "Bearer " + user.token }).then(
              (valid) => {
                if (valid) {
                  resolve(user);
                } else {
                  reject(null);
                }
              },
              (valid) => {
                reject(null);
              }
            );
          } else {
            reject(null);
          }
        },
        (error) => {
          reject(null);
        }
      );
    } catch (e) {
      reject(null);
    }
  });
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  validateCurrentUser,
};
