import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Constants from "expo-constants";
import authHeader from "./auth-header";
import authService from "./auth.service";

const CM_BASE_URL = Constants.manifest.extra.CM_BASE_URL;
const CM_ENVIRONMENT = Constants.manifest.extra.CM_ENVIRONMENT;

const sandbox_header = {};
if (CM_ENVIRONMENT && CM_ENVIRONMENT !== "primary") {
  sandbox_header["X-Sandbox"] = CM_ENVIRONMENT;
}

const cache_headers = {
  "cache-control": "no-cache",
  pragma: "no-cache",
};

const combine = (data) => {
  if (data.outline) {
    combineLanguage(data.outline, data.views, "sv");
    combineLanguage(data.outline, data.views, "en");
  }
  if (data.exercise) {
    combineLanguage(data.exercise, data.views, "sv");
    combineLanguage(data.exercise, data.views, "en");
  }
  return data;
};
const combineLanguage = (outline, views, lang) => {
  for (let i = 0; i < outline.chapters.length; i++) {
    const chapter = outline.chapters[i];
    for (let j = 0; j < chapter.subchapters.length; j++) {
      const subchapter = chapter.subchapters[j];
      if (subchapter.screenid) {
        copyViewData(subchapter, views, subchapter.screenid, lang);
        for (let k = 0; k < subchapter.substeps.length; k++) {
          const substep = subchapter.substeps[k];
          if (substep.screenid) {
            copyViewData(substep, views, substep.screenid, lang);
          }
        }
      }
    }
  }
};
const copyViewData = (obj, views, viewId, lang) => {
  const currentview = views.find((view) => {
    return view.id === viewId;
  });
  if (currentview) {
    if (currentview.type) {
      obj.type = currentview.type;
      obj.color = currentview.color;
      obj.force_landscape = currentview.force_landscape;
      obj.continue_automatically = currentview.continue_automatically;
    }

    //if (currentview[lang]) {
    Object.entries(currentview).map((prop) => {
      obj[prop[0]] = prop[1];
    });
    //}
  }
};
const internalRefresh = (name, data) => {
  return new Promise((resolve, reject) => {
    try {
      const thedata =
        data !== undefined && data.data !== undefined ? data.data : data;
      AsyncStorage.setItem(name, JSON.stringify(thedata)).then(() => {
        resolve(thedata);
      });
    } catch (e) {
      reject(e);
    }
  });
};
const internalGet = (name) => {
  return new Promise((resolve, reject) => {
    try {
      AsyncStorage.getItem(name).then((data) => {
        resolve(data);
      });
    } catch (e) {
      reject(e);
    }
  });
};
const getPublicContent = () => {
  return new Promise((resolve, reject) => {
    //axios.get(API_URL + "public").then(
    axios
      .get(CM_BASE_URL + "public", {
        headers: { ...sandbox_header, ...cache_headers },
      })
      .then(
        (data) => {
          data = { data: data?.data?.views }; //New backend adds 'views' root element to public endpoint
          internalRefresh("publiccontent", data).then(
            (storeddata) => {
              resolve(storeddata);
            },
            (error) => {
              reject(error);
            }
          );
        },
        (error) => {
          internalGet("publiccontent").then(
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }
      );
  });
};

const getProtectedContent = () => {
  return new Promise((resolve, reject) => {
    authHeader().then(
      (auth) => {
        //axios.get(API_URL + "protected", { headers: auth }).then(
        axios
          .get(CM_BASE_URL + "protected", {
            headers: {
              ...auth,
              ...sandbox_header,
              ...cache_headers,
            },
          })
          .then(
            (data) => {
              internalRefresh("protectedcontent", combine(data.data)).then(
                (storeddata) => {
                  resolve(storeddata);
                },
                (error) => {
                  reject(error);
                }
              );
            },
            (error) => {
              internalGet("protectedcontent").then(
                (data) => {
                  resolve(data);
                },
                (error) => {
                  reject(error);
                }
              );
            }
          );
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const postResult = async (result) => {
  const user = await authService.getCurrentUser();
  const auth = { Authorization: "Bearer " + user.token };
  return new Promise((resolve, reject) => {
    axios
      .post(CM_BASE_URL + "sendinfo", result, {
        headers: { ...auth, ...cache_headers },
      })
      .then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response.data);
          }
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const postSurveyAnswers = async (answers, rid) => {
  const user = await authService.getCurrentUser();
  const auth = { Authorization: "Bearer " + user.token };
  const data = { fields: answers, rid };

  return new Promise((resolve, reject) => {
    axios
      .post(CM_BASE_URL + "response", data, {
        headers: { ...auth, ...cache_headers },
      })
      .then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export default {
  getPublicContent,
  getProtectedContent,
  postResult,
  postSurveyAnswers,
};
