import i18n from "i18n-js";
i18n.defaultLocale = "sv";
i18n.locale = "sv";
i18n.fallbacks = true;

i18n.translations = {
  en: {
    appName: "Insightgap",
    appTitle: "%{appName} - %{view}",
    profile: "Profile",
    navigation: {
      home: "Home",
      profile: "Profile",
      register: "Register",
      login: "Login",
      education: "Education",
      exercise: "Exercise",
      outline: "Education",
    },
    inputs: {
      email: "E-mail",
      password: "Password",
      customercode: "Customer code",
      privateemail: "",
    },
    labels: {
      email: "E-mail",
      password: "Password",
      registerpassword: "Välj ett personligt lösenord",
      customercode: "Kod (Finns i mailutskick)",
      alreadyregistered1: "Redan registrerad?",
      alreadyregistered2: "istället.",
      choosebaseemotion: "Välj en grundkänsla",
      noemotionchosen: "Ingen känsla vald",
      mailsent: "Skickat!",
      customemotion: "Use a custom word",
      customemotion_desc:
        "Pick an icon and type a word to describe your emotions.",
      customemotion_placeholder: "I feel...",
    },
    buttons: {
      login: "Login",
      logout: "Log out",
      register: "Register",
      begin: "Begin",
      restart: "Restart",
      continue: "Continue",
      playagain: "Play again",
      showmore: "Show more",
      skip: "Skip",
      back: "Back",
      next: "Next",
      close: "Close",
      finish: "Finish",
      survey: "Survey",
      done: "Done",
      sendemail: "Send to my email",
      exploreagain: "Create new",
      send: "Send",
      directtoexercise: "Go to exercise",
    },
    educationstep: {
      heading: "Insightgap",
    },
    home: {
      welcome: "Welcome to %{appName}!",
    },
    outline: {
      education: "Content",
      exercise: "Excercise",
    },
    messages: {
      alreadyregistered: "Den e-postadress du angivit finns redan registrerad",
      invalidemail:
        "Den e-postadress du angivit kan inte hittas bland godkända adresser",
      codeincorrect:
        "Koden du angav matchar inte, vänligen kontrollera att rätt kod angivits",
      storageerror: "",
      communicationerror: "",
      generic: "Något gick fel, vänligen försök igen",
      loginerror: "Inloggningen misslyckades, vänligen försök igen",
      empty_password: "Lösenord måste anges",
      empty_username: "E-post måste anges",
    },
    emotions: {
      variant1: {
        joy: "joy",
        lust: "lust",
        fear: "fear",
        anger: "anger",
        shame: "shame",
        sadness: "sadness",
        distaste: "distaste",
        unchosen: "like this",
        custom: "like this",
      },
      variant2: {
        joy: "joy",
        lust: "lust",
        fear: "fear",
        anger: "anger",
        shame: "shame",
        sadness: "sadness",
        distaste: "distaste",
        unchosen: "like this",
        custom: "like this",
      },
      variant2: {
        joy: "joy",
        lust: "lust",
        fear: "fear",
        anger: "anger",
        shame: "shame",
        sadness: "sadness",
        distaste: "distaste",
        unchosen: "like this",
        custom: "like this",
      },
    },
  },
  sv: {
    appName: "Insightgap",
    appTitle: "%{appName} - %{view}",
    profile: "Användarprofil",
    navigation: {
      home: "Hem",
      profile: "Användarprofil",
      register: "Registera",
      login: "Logga in",
      education: "Hem",
      exercise: "Övning",
      outline: "Översikt",
    },
    inputs: {
      email: "E-post",
      password: "Lösenord",
      customercode: "Aktiveringskod",
      privateemail: "Fyll i din e-post",
    },
    labels: {
      email: "E-postadress",
      password: "Lösenord",
      registerpassword: "Välj ett personligt lösenord",
      customercode: "Aktiveringskod",
      alreadyregistered1: "Redan registrerad?",
      alreadyregistered2: "istället.",
      choosebaseemotion: "Välj en grundkänsla",
      noemotionchosen: "Ingen känsla vald",
      mailsent: "Skickat!",
      customemotion: "Använd ett eget ord",
      customemotion_desc:
        "Välj en ikon och skriv ett ord som beskriver det du upplever och känner.",
      customemotion_placeholder: "Jag känner mig...",
      action_plan: "Handlingsplan",
    },
    buttons: {
      login: "Logga in",
      logout: "Logga ut",
      register: "Aktivera konto",
      begin: "Starta utbildningen",
      restart: "Starta om utbildningen",
      continue: "Fortsätt",
      playagain: "Spela igen",
      showmore: "Info och tips",
      skip: "Hoppa över",
      back: "Bakåt",
      next: "Nästa",
      close: "Stäng",
      finish: "Avsluta",
      survey: "Utvärdering",
      done: "Klar",
      sendemail: "Skicka till min e-post",
      exploreagain: "Skapa ny handlingsplan",
      send: "Skicka",
      directtoexercise: "Gå till övning",
      print: "Skriv ut eller spara",
    },
    educationstep: {
      heading: "Insightgap",
    },
    home: {
      welcome: "Welcome to %{appName}!",
    },
    outline: {
      education: "Utbildning",
      exercise: "Övning",
    },
    messages: {
      alreadyregistered: "Den e-postadress du angivit finns redan registrerad",
      invalidemail:
        "Den e-postadress du angivit kan inte hittas bland godkända adresser",
      codeincorrect:
        "Koden du angav matchar inte, vänligen kontrollera att rätt kod angivits",
      storageerror:
        "Något gick fel när användaruppgifter skulle sparas, vänligen försök igen",
      communicationerror:
        "Något gick fel i kommunikationen med servern, vänligen försök igen",
      generic: "Något gick fel, vänligen försök igen",
      loginerror: "Inloggningen misslyckades, vänligen försök igen",
      empty_password: "Lösenord måste anges",
      empty_username: "E-post måste anges",
    },
    emotions: {
      variant1: {
        joy: "dig glad",
        lust: "dig lustfylld",
        fear: "dig rädd",
        anger: "dig arg",
        shame: "dig skamsen",
        sadness: "dig ledsen",
        distaste: "äckel",

        happy: "dig lycklig",
        content: "dig belåten",
        excited: "dig uppspelt",
        desire: "önskan",
        want: "begär",
        longing: "längtan",
        taggad: "dig taggad",
        worry: "dig orolig",
        nervous: "dig nervös",
        panic: "panik",
        stress: "dig stressad",
        desperation: "dig desperat",
        scared: "dig rädd",
        irritation: "dig irriterad",
        frustration: "dig frustrerad",
        mad: "dig galen",
        vengeful: "dig hämndlysten",
        furious: "dig rasande",
        bitter: "dig bitter",
        useless: "dig oduglig",
        weak: "dig svag",
        immoral: "dig omoralisk",
        worthless: "dig mindre värd",
        bad: "dig dålig",
        sorrow: "dig sorgsen",
        sad: "dig ledsen",
        devastaded: "dig bedrövad",
        disappointed: "dig besviken",
        hopelessness: "dig hopplös",
        down: "dig nedstämd",
        disgust: "äckel",
        loathing: "avsky",
        aversion: "motvilja",
        contempt: "förakt",

        inlove: "dig förälskad",
        anxiety: "ångest",
        ashamed: "skam",
        guilty: "skuld",

        unchosen: "dig så här",
        custom: "dig så här",
      },
      variant2: {
        joy: "din glädje",
        lust: "din lust",
        fear: "din rädsla",
        anger: "din ilska",
        shame: "din skam",
        sadness: "din sorg",
        distaste: "din avsmak",

        happy: "din lycka",
        content: "din belåtenhet",
        excited: "din uppspelthet",
        desire: "dina önskan",
        want: "ditt begär",
        longing: "din längtan",
        taggad: "din känsla",
        worry: "din oro",
        nervous: "din nervositet",
        panic: "din panik",
        stress: "din stress",
        desperation: "din desperation",
        scared: "din rädsla",
        irritation: "din irritation",
        frustration: "din frustration",
        mad: "din känsla av galenskap",
        vengeful: "din hämndlystnad",
        furious: "ditt raseri",
        bitter: "din bitterhet",
        useless: "din känsla av oduglighet",
        weak: "din känsla av svaghet",
        immoral: "din känsla av omoral",
        worthless: "din känsla av mindre värde",
        bad: "din känsla av att vara dålig",
        sorrow: "din sorgsenhet",
        sad: "din ledsamhet",
        devastaded: "din bedrövelse",
        disappointed: "din besvikelse",
        hopelessness: "din hopplöshet",
        down: "din nedstämdhet",
        disgust: "din känsla av äckel",
        loathing: "din avsky",
        aversion: "din motvilja",
        contempt: "ditt förakt",

        inlove: "din förälskelse",
        anxiety: "din ångest",
        ashamed: "din skam",
        guilty: "din skuld",

        unchosen: "din känsla",
        custom: "din känsla",
      },
    },
  },
};
export default i18n;
