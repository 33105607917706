import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { Texts, Theme } from "../styles";
import { Spacer } from "./Spacer";

const Accordion = ({ text, open, children }) => {
  const [accordionOpen, setAccordionOpen] = useState(open);

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  return (
    <View>
      <TouchableOpacity
        onPress={toggleAccordion}
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 20,
            height: 20,
            borderRadius: 20,
            backgroundColor: Theme.disabled,
          }}
        >
          <Icon
            color={Theme.textColorLight}
            name={
              accordionOpen ? "keyboard-arrow-down" : "keyboard-arrow-right"
            }
            size={24}
            type="material"
          />
        </View>
        <Spacer size="small" />
        <Text style={Texts.accordationTitle}>{text}</Text>
      </TouchableOpacity>
      {accordionOpen && (
        <>
          <Spacer size="medium" />
          <Text style={Texts.body}>{children}</Text>
        </>
      )}
      <Spacer size="medium" />
    </View>
  );
};

export default Accordion;
