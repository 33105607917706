import { Platform } from "react-native";
import { inputLabel, inputText } from "./texts";
import { Theme } from "./theme";

export const label = {
  ...inputLabel,
  textTransform: "uppercase",
  color: Theme.inputLabelColor,
};

const outlineStyle = Platform.OS === "web" ? { outline: "none" } : {};

export const inputField = {
  ...inputText,
  width: "100%",
  margin: 0,
  padding: 10,
  borderWidth: 1,
  borderStyle: "solid",
  backgroundColor: Theme.inputBg,
  borderColor: Theme.inputBorder,
  borderRadius: Theme.inputRadius,
  ...outlineStyle,
};

export const textArea = {
  ...inputField,
  padding: 20,
};
