import React, { useState } from "react";
import { Modal, Text, TextInput, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { getColorizedEmotion } from "../helpers/svg-assets";
import { useGlobalState } from "../services/store";
import i18n from "../services/translations";
import { Inputs, Texts, Theme } from "../styles";
import { buttonLabel } from "../styles/texts";
import { PrimaryButton } from "./Buttons";
import { Spacer } from "./Spacer";

export const CustomEmotionModalButton = ({ onPress }) => (
  <TouchableOpacity
    style={{
      display: "inline-flex",
      alignItems: "center",
      width: `${(1 / 3) * 100}%`,
      marginBottom: 32,
      verticalAlign: "top",
    }}
    onPress={onPress}
  >
    <View
      style={{
        height: 55,
        width: 55,
        borderRadius: "50%",
        marginBottom: 12,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Theme.secondary,
      }}
    >
      <Icon
        color={Theme.textColorLight}
        name={"add"}
        size={28}
        type="material"
      />
    </View>
    <View
      style={{
        height: 32,
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          ...buttonLabel,
          textAlign: "center",
        }}
      >
        {i18n.t("labels.customemotion")}
      </Text>
    </View>
  </TouchableOpacity>
);

export const CustomEmotionModal = ({ isOpen, onClose, onDone }) => {
  const { state } = useGlobalState();
  const [customEmotion, setCustomEmotion] = useState("");
  const [baseEmotionId, setBaseEmotionId] = useState(state.baseemotions[0].id);

  return (
    <Modal animationType="fade" transparent={true} visible={isOpen}>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(30, 52, 80, 0.7)",
        }}
      >
        <View
          style={{
            backgroundColor: Theme.modalBg,
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 12,
            maxWidth: 340,
            marginBottom: 20,
            position: "relative",
          }}
        >
          <TouchableOpacity
            style={{
              position: "absolute",
              top: 24,
              right: 24,
            }}
            onPress={onClose}
          >
            <Icon
              color={Theme.textColor}
              name="close"
              size={28}
              type="material"
            />
          </TouchableOpacity>

          <Text style={Texts.h3}>{i18n.t("labels.customemotion")}</Text>
          <Spacer size="small" />
          <Text style={Texts.bodyBold}>
            {i18n.t("labels.customemotion_desc")}
          </Text>
          <Spacer size="small" />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              textAlign: "center",
              marginTop: 10,
              marginLeft: -10,
              marginRight: -10,
              marginBottom: 10,
            }}
          >
            {state.baseemotions.map((e) => {
              const isSelected = e.id === baseEmotionId;

              return (
                <TouchableOpacity
                  key={`emotion_id_${e.id}`}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  onPress={() => setBaseEmotionId(e.id)}
                >
                  <View
                    style={{
                      height: 55,
                      width: 55,
                      margin: 10,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {getColorizedEmotion(
                      e.id,
                      isSelected ? Theme.primary : Theme.secondary
                    )}
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
          <Spacer size="small" />
          <TextInput
            placeholder={i18n.t("labels.customemotion_placeholder")}
            multiline={false}
            style={Inputs.inputField}
            value={customEmotion}
            onChangeText={setCustomEmotion}
          />
          <Spacer size="medium" />
          <PrimaryButton
            onPress={() => onDone({ customEmotion, baseEmotionId })}
            text={i18n.t("buttons.continue")}
            style={{ alignSelf: "center" }}
            disabled={customEmotion.length === 0}
          />
        </View>
      </View>
    </Modal>
  );
};
