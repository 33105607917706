import React, { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import AuthService from "./services/auth.service";
import ContentService from "./services/content.service";
import { trackAppStart, useGlobalState } from "./services/store";
import { Theme } from "./styles";

const Root = (props) => {
  const { state, dispatch } = useGlobalState();
  const [appIsReady, setAppIsReady] = useState(false);
  const getFlattendedSubsteps = (outline) => {
    const flattenedSubchapters = [];
    for (let i = 0; i < outline.chapters.length; i++) {
      const chapter = outline.chapters[i];
      for (let j = 0; j < chapter.subchapters.length; j++) {
        const subchapter = chapter.subchapters[j];
        for (let k = 0; k < subchapter.substeps.length; k++) {
          flattenedSubchapters.push({
            chapter: chapter,
            subchapter: subchapter,
            substep: subchapter.substeps[k],
          });
        }
      }
    }
    return flattenedSubchapters;
  };
  useEffect(() => {
    async function startup() {
      await AuthService.validateCurrentUser().then(
        (user) => {
          trackAppStart(state.user);
          dispatch({ type: "SET_USER", payload: user });
        },
        (error) => {
          dispatch({ type: "SET_USER", payload: null });
          trackAppStart();
        }
      );
      await ContentService.getPublicContent().then(
        (content) => {
          dispatch({ type: "SET_PUBLIC_CONTENT", payload: content });
        },
        (error) => {
          dispatch({ type: "SET_PUBLIC_CONTENT", payload: [] });
        }
      );
      setAppIsReady(true);
    }
    startup();
  }, []);

  useEffect(() => {
    if (state.isLoggedIn) {
      ContentService.getProtectedContent().then(
        (content) => {
          dispatch({ type: "SET_PROTECTED_CONTENT", payload: content });
        },
        (error) => {
          dispatch({ type: "SET_PROTECTED_CONTENT", payload: [] });
        }
      );
    } else {
      dispatch({ type: "SET_PROTECTED_CONTENT", payload: [] });
    }
  }, [state.isLoggedIn]);
  useEffect(() => {
    if (state.protectedcontent?.outline) {
      const substeps = getFlattendedSubsteps(state.protectedcontent.outline);
      dispatch({ type: "SET_SUBSTEPS", payload: substeps });

      dispatch({
        type: "SET_OUTLINE",
        payload: state.protectedcontent.outline,
      });
    } else {
      dispatch({ type: "SET_OUTLINE", payload: [] });
      dispatch({ type: "SET_SUBSTEPS", payload: [] });
    }
    if (state.protectedcontent?.emotions) {
      dispatch({
        type: "SET_EMOTIONS",
        payload: state.protectedcontent.emotions.baseemotions,
      });
    } else {
      dispatch({ type: "SET_EMOTIONS", payload: [] });
    }
    if (state.protectedcontent?.exercise) {
      if (state.protectedcontent.exercise) {
        const exercise = getFlattendedSubsteps(state.protectedcontent.exercise);
        dispatch({ type: "SET_EXERCISE_SUBSTEPS", payload: exercise });
      }
      dispatch({
        type: "SET_EXERCISE",
        payload: state.protectedcontent.exercise,
      });
    } else {
      dispatch({ type: "SET_EXERCISE", payload: [] });
      dispatch({ type: "SET_EXERCISE_SUBSTEPS", payload: [] });
    }
  }, [state.protectedcontent, state.locale]);

  return (
    <>
      {!appIsReady && (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={Theme.primary} />
        </View>
      )}
      {appIsReady && <View style={styles.container}>{props.children}</View>}
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    alignContent: "flex-start",
  },
});
export default Root;
