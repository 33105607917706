import React from "react";
import { FlatList, Text, View } from "react-native";
import HTML from "react-native-render-html";
import CenteredParagraph from "../components/CenteredParagraph";
import Header from "../components/Header";
import ImageComponent from "../components/Image";
import Ingress from "../components/Ingress";
import NextButton from "../components/NextButton";
import Paragraph from "../components/Paragraph";
import ResultView from "../components/Result/ResultView";
import SpacerElement from "../components/SpacerElement";
import StartButtons from "../components/StartButtons";
import StepButton from "../components/StepButton";
import UnderlineIngress from "../components/UnderlineIngress";
import VideoContent from "../components/VideoContent";
import { Texts } from "../styles";

const KeysToComponentMap = {
  text: { component: Text, type: "text" },
  header: { component: Header, type: "header" },
  chapter: { component: Text, type: "text" },
  ingress: { component: Ingress, type: "ingress" },
  image: { component: ImageComponent, type: "image" },
  underline_ingress: { component: UnderlineIngress, type: "underline_ingress" },
  paragraph: { component: Paragraph, type: "paragraph" },
  centered_paragraph: {
    component: CenteredParagraph,
    type: "centered_paragraph",
  },
  result_view: { component: ResultView, type: "result_view" },
  show_next_button: { component: NextButton, type: "show_next_button" },
  step_button: { component: StepButton, type: "step_button" },
  spacer_element: { component: SpacerElement, type: "spacer_element" },
  start_button_set: { component: StartButtons, type: "start_button_set" },
  video: { component: VideoContent, type: "video" },
  list: { component: Text, type: "list" },
  json: { component: View, type: "json" },
  wysiwyg: { component: HTML, type: "html" },
};

const renderUnordered = ({ item }) => <Text>{`\u2022 ${item}`}</Text>;

const renderOrdered = ({ item, index }) => (
  <Text>{`${index + 1}. ${item}`}</Text>
);

const renderjson = (config, index) => {
  const componentName = config["type"];

  if (typeof KeysToComponentMap[componentName] !== "undefined") {
    const content = config.data;
    if (KeysToComponentMap[componentName].type == "text") {
      return React.createElement(
        KeysToComponentMap[componentName].component,
        {
          key: "view_" + index,
        },
        content.text
      );
    }
    if (KeysToComponentMap[componentName].type == "html") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        source: { html: content.text },
        tagsStyles: {
          p: Texts.base,
        },
      });
    }
    if (KeysToComponentMap[componentName].type == "list") {
      const isOrdered = content.style == "ordered";
      return React.createElement(
        View,
        {
          key: "view_" + index,
        },
        <FlatList
          data={content.items}
          renderItem={isOrdered ? renderOrdered : renderUnordered}
          keyExtractor={(x, i) => "view_" + index + "listitem_" + i.toString()}
          style={{ margin: 40 }}
        />
      );
    }
  }
};
export const isComponent = (config, name) => {
  return config["acf_fc_layout"] === name;
};

const renderer = (
  config,
  index,
  style = undefined,
  openModal = undefined,
  customProps = undefined,
  state = undefined,
  color = undefined
) => {
  const componentName = config["acf_fc_layout"];

  if (typeof KeysToComponentMap[componentName] !== "undefined") {
    const content = config[componentName];
    if (KeysToComponentMap[componentName].type == "text") {
      const passedStyle = style !== undefined ? style : {};
      const theStyle = { ...passedStyle };
      return React.createElement(
        KeysToComponentMap[componentName].component,
        {
          key: "view_" + index,
          style: theStyle,
        },
        content
      );
    }
    if (KeysToComponentMap[componentName].type == "image") {
      const image = config["image"]?.url;
      const position = config["position"];

      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        image: image,
        position: position,
        style: style,
        maxWidth: config["maxwidth"],
        maxHeight: config["maxheight"],
        color: config["color"],
        borderRadius: config["border_radius"],
        padding: config["padding"],
      });
    }
    if (KeysToComponentMap[componentName].type == "underline_ingress") {
      const underlined = config["underlined"];
      const ingress = config["ingress"];
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        underlined: underlined,
        ingress: ingress,
        style: style,
      });
    }
    if (KeysToComponentMap[componentName].type == "ingress") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        data: config,
        style: style,
      });
    }
    if (KeysToComponentMap[componentName].type == "paragraph") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        data: config,
        style: style,
      });
    }
    if (KeysToComponentMap[componentName].type == "header") {
      return React.createElement(
        KeysToComponentMap[componentName].component,
        {
          key: "view_" + index,
          position: config.position,
          level: config.level,
          data: config,
          style: style,
        },
        config.header
      );
    }
    if (KeysToComponentMap[componentName].type == "show_next_button") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        customProps,
      });
    }
    if (KeysToComponentMap[componentName].type == "step_button") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        customProps,
        data: config,
        style: style,
      });
    }
    if (KeysToComponentMap[componentName].type == "start_button_set") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        customProps,
        restart: config["restart"],
        style: style,
      });
    }
    if (KeysToComponentMap[componentName].type == "spacer_element") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        height: isNaN(config["height"]) ? null : config["height"],
        width: isNaN(config["width"]) ? null : config["width"],
      });
    }
    if (KeysToComponentMap[componentName].type == "result_view") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        resultviews: config["result_views"],
        emotionview: config["emotion_view"]?.view_id,
        openModal,
      });
    }
    if (KeysToComponentMap[componentName].type == "video") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        url: config["url"],
        url_landscape: config["url_landscape"],
      });
    }
    if (KeysToComponentMap[componentName].type == "html") {
      return React.createElement(KeysToComponentMap[componentName].component, {
        key: "view_" + index,
        source: { html: content || "" },
        tagsStyles: {
          p: Texts.base,
        },
      });
    }
    if (KeysToComponentMap[componentName].type == "json") {
      const json = JSON.parse(content);
      if (json && json.blocks && json.blocks.length > 0) {
        return React.createElement(
          View,
          {
            key: "view_" + index,
          },
          json.blocks.map((block, blockindex) => renderjson(block, blockindex))
        );
      }
    }
  }
};

export default renderer;
