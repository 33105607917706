import React from "react";
import { View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { Texts } from "../styles";
import { Spacer } from "./Spacer";

function Paragraph(props) {
  let textAlign = "left";

  if (props.data?.position) {
    textAlign = props.data?.position;
  }

  let externalstyle = {};
  if (props.style) {
    externalstyle = props.style;
  }

  const styles = {
    paragraph: {
      flexDirection: "column",
      alignSelf: "center",
      alignItems: textAlign == "center" ? "center" : "flex-start",
      width: "100%",
      ...externalstyle,
    },
    text: {
      ...Texts.body,
      flexWrap: "wrap",
      textAlign: textAlign,
      ...externalstyle,
    },
  };

  if (props.data.max_width) {
    if (props.data.width_unit === "percent") {
      styles.text.maxWidth = `${props.data.max_width}%`;
    } else {
      styles.text.maxWidth = `${props.data.max_width}px`;
    }
  }

  if (props.data.font_size) {
    styles.text.fontSize = props.data.font_size;
  }

  const matchedText = (matchingString, matches) => {
    if (matches.length > 1) {
      return matches[1];
    } else {
      return matchingString;
    }
  };

  return (
    <View style={styles.paragraph}>
      <ParsedText
        style={styles.text}
        parse={[
          {
            pattern: /{b:([^}]+)}/i,
            style: { fontFamily: Texts.FONT_FAMILYS.BODY_BOLD },
            renderText: matchedText,
          },
          {
            pattern: /{l:([^}]+)}/i,
            style: { fontFamily: Texts.FONT_FAMILYS.BODY_LIGHT },
            renderText: matchedText,
          },
        ]}
        childrenProps={{ allowFontScaling: false }}
      >
        {props.data.paragraph}
      </ParsedText>
      <Spacer size="medium" />
    </View>
  );
}

export default Paragraph;
