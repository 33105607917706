import React from "react";
import { Image, Text, View } from "react-native";
import { Texts } from "../styles";

const IconHeader = (props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 25,
      }}
    >
      {props.image?.url && (
        <Image
          style={{
            resizeMode: "contain",
            width: 96,
            height: 96,
            marginRight: 28,
          }}
          source={{
            uri: props.image.url,
          }}
        />
      )}
      <View style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
        <Text style={Texts.keyword}>{props.chapter_keyword}</Text>
        <Text style={Texts.h2}>{props.title}</Text>
      </View>
    </View>
  );
};
export default IconHeader;
